import React, { useState, useEffect, useRef } from "react";
import {
  BellOutlined,
  CheckOutlined,
  CommentOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Badge, Dropdown, Spin, Empty, Button, Tabs, Tag } from "antd";
import {
  getUnreadCount,
  getNotifications,
  markAsRead,
  markAllAsRead,
} from "../../services/notificationService";
import { useNavigate, useLocation } from "react-router-dom";
import timeAgo from "../../utils/timeAgo";

const NotificationBell = ({ role }) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [included, setIncluded] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("unread");
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      // Fetch unread count
      const count = await getUnreadCount();
      setUnreadCount(count);

      // Fetch notifications
      const response = await getNotifications();
      if (response && Array.isArray(response.data)) {
        setNotifications(response.data);
        // Store included data separately for user info
        if (response.included && Array.isArray(response.included)) {
          setIncluded(response.included);
        }
      } else {
        setNotifications([]);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotifications([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();

    // Set up polling for new notifications every 5 minutes
    const intervalId = setInterval(fetchNotifications, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const handleNotificationClick = async (notification) => {
    try {
      // Extract the product ID from the notification
      const productId = notification.attributes.notifiable_id;

      // Mark as read first to ensure it happens
      if (!notification.attributes.read) {
        await markAsRead(notification.id);

        // Update local state
        setNotifications((prev) =>
          prev.map((item) =>
            item.id === notification.id
              ? { ...item, attributes: { ...item.attributes, read: true } }
              : item
          )
        );

        // Update unread count
        setUnreadCount((prev) => Math.max(0, prev - 1));
      }

      // Navigate to the product page
      if (role === "supplier") {
        navigate(`/${role}/products/${productId}`);
      } else if (role === "admin") {
        navigate(`/${role}/manage-products/${productId}`);
      } else if (role === "brand") {
        navigate(`/${role}/products/${productId}`);
      }

      // Close the dropdown
      setOpen(false);
    } catch (error) {
      console.error("Error handling notification click:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead();

      // Update local state
      setNotifications((prev) =>
        prev.map((item) => ({
          ...item,
          attributes: { ...item.attributes, read: true },
        }))
      );

      setUnreadCount(0);
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  // Parse notification action to determine type and status
  const parseNotificationType = (notification) => {
    const { action } = notification.attributes;

    // Check if it's a comment
    if (action === "commented") {
      return {
        type: "comment",
        status: null,
        icon: <CommentOutlined style={{ fontSize: "16px" }} />,
        color: "blue",
        label: "Comment",
      };
    }

    // Check for status updates
    if (action.includes("updated_status")) {
      if (action.includes("to-forced_submitted")) {
        return {
          type: "status",
          status: "forced-submitted",
          icon: <ExclamationCircleOutlined style={{ fontSize: "16px" }} />,
          color: "orange",
          label: "Forced Submitted",
        };
      } else if (action.includes("to-submitted")) {
        return {
          type: "status",
          status: "submitted",
          icon: <CheckCircleOutlined style={{ fontSize: "16px" }} />,
          color: "green",
          label: "Submitted",
        };
      } else if (action.includes("to-refused")) {
        return {
          type: "status",
          status: "refused",
          icon: <CloseCircleOutlined style={{ fontSize: "16px" }} />,
          color: "red",
          label: "Refused",
        };
      }
    }

    // Default for general updates
    return {
      type: "update",
      status: null,
      icon: <SyncOutlined style={{ fontSize: "16px" }} />,
      color: "purple",
      label: "Update",
    };
  };

  // Filter notifications by read status
  const unreadNotifications = notifications.filter((n) => !n.attributes.read);
  const readNotifications = notifications.filter((n) => n.attributes.read);

  // Group notifications by date for better organization
  const groupNotificationsByDate = (notificationList) => {
    const grouped = {};
    notificationList.forEach((notification) => {
      const date = new Date(
        notification.attributes.created_at
      ).toLocaleDateString();
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(notification);
    });
    return grouped;
  };

  const groupedUnreadNotifications =
    groupNotificationsByDate(unreadNotifications);
  const groupedReadNotifications = groupNotificationsByDate(readNotifications);

  // Render notification item
  const renderNotificationItem = (notification) => {
    // Find actor data
    const actor = notification.relationships.actor.data;
    const userData = included.find(
      (item) => item.id === actor.id && item.type === actor.type
    );
    const username = userData?.attributes?.name || "Unknown User";
    const displayName = username;
    const isUnread = !notification.attributes.read;

    // Get notification type details
    const notificationDetails = parseNotificationType(notification);

    // Determine if it's a system notification
    const isSystem = displayName.toLowerCase().includes("system");

    // Format the notifiable type text
    const type = notification.attributes.notifiable_type
      .replace(/_/g, " ")
      .replace(/\b\w/g, (l) => l.toUpperCase());

    return (
      <div
        key={notification.id}
        onClick={() => handleNotificationClick(notification)}
        className={`p-4 cursor-pointer border-b border-gray-100 transition-colors ${
          isUnread ? "bg-blue-50" : "hover:bg-gray-50"
        }`}
      >
        <div className="flex items-start">
          <div className="flex-shrink-0 mr-3">
            <div
              className={`w-10 h-10 rounded-full flex items-center justify-center font-semibold ${
                isSystem
                  ? "bg-gray-100 text-gray-600"
                  : "bg-blue-100 text-blue-600"
              }`}
            >
              {displayName.charAt(0).toUpperCase()}
            </div>
          </div>

          <div className="flex-1 min-w-0">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <div className="text-sm font-medium text-gray-900">
                  {displayName}
                </div>
                {isUnread && (
                  <span className="ml-2 w-2 h-2 bg-blue-600 rounded-full flex-shrink-0"></span>
                )}
              </div>
              <div className="text-xs text-gray-500 ml-2 whitespace-nowrap">
                {timeAgo(notification.attributes.created_at)}
              </div>
            </div>

            <div className="mt-2 flex items-center">
              <span className="text-gray-600">{notificationDetails.icon}</span>
              <Tag color={notificationDetails.color} className="ml-2 text-xs">
                {notificationDetails.label}
              </Tag>
            </div>

            <p className="mt-2 text-sm text-gray-600 line-clamp-2">
              {notification.attributes.message}
            </p>

          </div>
        </div>
      </div>
    );
  };

  // Render notification list by date
  const renderNotificationsByDate = (groupedNotifications) => {
    if (Object.keys(groupedNotifications).length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            activeTab === "unread"
              ? "No new notifications"
              : "No read notifications"
          }
          className="py-8"
        />
      );
    }

    return (
      <>
        {Object.entries(groupedNotifications).map(
          ([date, items], groupIndex) => (
            <div
              key={date}
              className={
                groupIndex < Object.keys(groupedNotifications).length - 1
                  ? "mb-3"
                  : ""
              }
            >
              <div className="px-4 py-2 text-xs font-medium text-gray-500 bg-gray-50 sticky top-0">
                {date}
              </div>
              <div>
                {items.map((notification, index) => (
                  <div
                    key={notification.id}
                    className={index < items.length - 1 ? "mb-2" : ""}
                  >
                    {renderNotificationItem(notification)}
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </>
    );
  };

  // Custom tab items
  const tabItems = [
    {
      key: "unread",
      label: (
        <span className="px-1">
          New
          {unreadCount > 0 && (
            <span className="ml-2 px-2 py-0.5 text-xs bg-blue-100 text-blue-800 rounded-full">
              {unreadCount}
            </span>
          )}
        </span>
      ),
      children: (
        <div
          className="overflow-y-auto custom-scrollbar"
          ref={scrollRef}
          style={{
            maxHeight: "400px",
            scrollbarWidth: "thin",
            scrollbarColor: "#e5e7eb transparent",
          }}
        >
          {loading ? (
            <div className="flex justify-center items-center py-8">
              <Spin size="default" />
            </div>
          ) : (
            renderNotificationsByDate(groupedUnreadNotifications)
          )}
        </div>
      ),
    },
    {
      key: "read",
      label: <span className="px-1">Read</span>,
      children: (
        <div
          className="overflow-y-auto custom-scrollbar"
          style={{
            maxHeight: "400px",
            scrollbarWidth: "thin",
            scrollbarColor: "#e5e7eb transparent",
          }}
        >
          {loading ? (
            <div className="flex justify-center items-center py-8">
              <Spin size="default" />
            </div>
          ) : (
            renderNotificationsByDate(groupedReadNotifications)
          )}
        </div>
      ),
    },
  ];

  const dropdownMenu = (
    <div
      className="rounded-lg shadow-lg overflow-hidden"
      style={{
        width: 370,
        border: "1px solid #eaecef",
        backgroundColor: "#fff",
      }}
    >
      {/* Header */}
      <div className="px-4 py-3 border-b border-gray-200 flex justify-between items-center bg-white">
        <h3 className="text-base font-semibold text-gray-900 m-0">
          Notifications
        </h3>
        {unreadCount > 0 && (
          <Button
            type="text"
            onClick={handleMarkAllAsRead}
            className="text-blue-600 hover:text-blue-800 flex items-center text-sm"
            icon={<CheckOutlined className="text-xs" />}
            size="small"
          >
            Mark all as read
          </Button>
        )}
      </div>

      {/* Tabs */}
      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        className="notification-tabs"
        tabBarStyle={{
          margin: "0 16px",
          borderBottom: "1px solid #eaecef",
        }}
        items={tabItems}
      />
    </div>
  );

  return (
    <Dropdown
      overlay={<>{dropdownMenu}</>}
      trigger={["click"]}
      open={open}
      onOpenChange={(visible) => {
        setOpen(visible);
        if (visible) {
          fetchNotifications();
        }
      }}
      placement="bottomRight"
      getPopupContainer={() => document.body}
      overlayStyle={{
        boxShadow: "0 6px 16px rgba(0, 0, 0, 0.08)",
      }}
    >
      <div className="cursor-pointer flex items-center justify-center h-10 w-10 rounded-full hover:bg-gray-100 transition-colors">
        <Badge count={unreadCount} overflowCount={99} size="small">
          <BellOutlined
            style={{ fontSize: "22px" }}
            className="text-gray-600 hover:text-blue-600"
          />
        </Badge>
      </div>
    </Dropdown>
  );
};

export default NotificationBell;
