// src/components/settings/Settings.js
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { updateUser } from "../../services/userService";
import {
  Form,
  Input,
  Button,
  message,
  Card,
  Tabs,
  Typography,
  Avatar,
  Divider,
  Row,
  Col,
  Switch,
  Select,
  Spin,
  Alert,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  BellOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  SaveOutlined,
  UndoOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const Settings = () => {
  const { user, updateUserData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState("profile");
  const [profileForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [userAttributes, setUserAttributes] = useState({});

  // Extract attributes safely
  useEffect(() => {
    // Handle both user formats (with or without attributes field)
    const attributes = user.attributes || user;
    setUserAttributes(attributes);

    // Set initial form values for profile data
    profileForm.setFieldsValue({
      email: attributes.email,
      name: attributes.name,
      first_name: attributes.first_name,
      phone: attributes.phone || "",
    });

    // Clear password fields each time user changes
    passwordForm.resetFields();
  }, [user, profileForm, passwordForm]);

  // Helper function to get the first letters of first and last name for avatar
  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    if (nameParts.length >= 2) {
      return `${nameParts[0][0]}${
        nameParts[nameParts.length - 1][0]
      }`.toUpperCase();
    }
    return name.substring(0, 2).toUpperCase();
  };

  // Get user ID safely
  const getUserId = () => {
    return user.attributes?.id || user.id;
  };

  const onProfileFinish = async (values) => {
    setSaving(true);
    try {
      const response = await updateUser(getUserId(), values);
      if (response.data) {
        // Properly merge the updated data with existing user data
        if (user.attributes) {
          // If user has attributes object
          const updatedUser = {
            ...user,
            attributes: {
              ...user.attributes,
              ...response.data.attributes,
            },
          };
          updateUserData(updatedUser);
        } else {
          // If user is already flat
          const updatedUser = {
            ...user,
            ...response.data.attributes,
          };
          updateUserData(updatedUser);
        }
        message.success("Profile updated successfully");
      }
    } catch (error) {
      console.error("Error updating profile", error);
      message.error("Failed to update profile. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  const onPasswordFinish = async (values) => {
    setSaving(true);
    try {
      // Extract just password fields
      const passwordData = {
        password: values.password,
        password_confirmation: values.password_confirmation,
      };

      const response = await updateUser(getUserId(), passwordData);
      if (response.data) {
        message.success("Password updated successfully");
        passwordForm.resetFields();
      }
    } catch (error) {
      console.error("Error updating password", error);
      message.error("Failed to update password. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  const resetProfile = () => {
    profileForm.setFieldsValue({
      email: userAttributes.email,
      name: userAttributes.name,
      first_name: userAttributes.first_name,
      phone: userAttributes.phone || "",
    });
    message.info("Form has been reset to original values");
  };

  if (!user) {
    return (
      <div className="flex justify-center items-center h-64">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="p-6">
      <Title level={2} className="mb-6">
        Account Settings
      </Title>

      <Row gutter={[24, 24]}>
        {/* Left Column - User Summary Card */}
        <Col xs={24} md={8}>
          <Card className="mb-6">
            <div className="flex flex-col items-center text-center">
              <Avatar
                size={100}
                icon={<UserOutlined />}
                style={{ backgroundColor: "#1890ff" }}
              >
                {getInitials(userAttributes.name)}
              </Avatar>
              <Title level={4} className="mt-4 mb-1">
                {userAttributes.name}
              </Title>
              <Text type="secondary">{userAttributes.email}</Text>

              <div className="mt-2">
                <Text type="secondary">
                  <Text strong>Role:</Text>{" "}
                  {userAttributes.role?.charAt(0).toUpperCase() +
                    userAttributes.role?.slice(1)}
                </Text>
              </div>

              {userAttributes.last_sign_in_at && (
                <div className="mt-4 text-xs text-gray-500">
                  Last login:{" "}
                  {new Date(userAttributes.last_sign_in_at).toLocaleString()}
                </div>
              )}
            </div>
          </Card>

          {/* Help Card
          <Card>
            <Title level={5}>Need Help?</Title>
            <Text>
              If you need assistance with your account settings, please contact
              support.
            </Text>
            <div className="mt-4">
              <Button type="default" block>
                Contact Support
              </Button>
            </div>
          </Card> */}
        </Col>

        {/* Right Column - Settings Tabs */}
        <Col xs={24} md={16}>
          <Card>
            <Tabs
              activeKey={activeTab}
              onChange={setActiveTab}
              tabPosition="top"
            >
              {/* Profile Settings */}
              <TabPane
                tab={
                  <span>
                    <UserOutlined /> Profile
                  </span>
                }
                key="profile"
              >
                <Form
                  form={profileForm}
                  layout="vertical"
                  onFinish={onProfileFinish}
                  requiredMark="optional"
                >
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Email is required" },
                          {
                            type: "email",
                            message: "Please enter a valid email address",
                          },
                        ]}
                      >
                        <Input
                          disabled
                          prefix={<MailOutlined className="text-gray-400" />}
                          placeholder="Email address"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                          { required: true, message: "Name is required" },
                        ]}
                      >
                        <Input
                          prefix={<UserOutlined className="text-gray-400" />}
                          placeholder="Full name"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="First Name"
                        name="first_name"
                        rules={[
                          { required: true, message: "First name is required" },
                        ]}
                      >
                        <Input placeholder="First name" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Phone" name="phone">
                        <Input
                          prefix={<PhoneOutlined className="text-gray-400" />}
                          placeholder="Phone number (optional)"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider />

                  <div className="flex justify-end space-x-4">
                    <Button onClick={resetProfile} icon={<UndoOutlined />}>
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={saving}
                      icon={<SaveOutlined />}
                    >
                      {saving ? "Saving..." : "Save Changes"}
                    </Button>
                  </div>
                </Form>
              </TabPane>

              {/* Password Settings */}
              <TabPane
                tab={
                  <span>
                    <LockOutlined /> Security
                  </span>
                }
                key="security"
              >
                <Title level={4}>Change Password</Title>
                <Text type="secondary" className="block mb-4">
                  Create a new password that is at least 6 characters long
                </Text>

                <Form
                  form={passwordForm}
                  layout="vertical"
                  onFinish={onPasswordFinish}
                  requiredMark="optional"
                >
                  <Form.Item
                    label="New Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a new password",
                      },
                      {
                        min: 6,
                        message: "Password must be at least 6 characters",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      prefix={<LockOutlined className="text-gray-400" />}
                      placeholder="New password"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Confirm New Password"
                    name="password_confirmation"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Passwords do not match")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      prefix={<LockOutlined className="text-gray-400" />}
                      placeholder="Confirm new password"
                    />
                  </Form.Item>

                  {userAttributes.force_password_change && (
                    <Alert
                      message="Password Change Required"
                      description="Your account requires a password change before you can continue using all features."
                      type="warning"
                      showIcon
                      className="mb-4"
                    />
                  )}

                  <Divider />

                  <div className="flex justify-end">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={saving}
                      icon={<SaveOutlined />}
                    >
                      {saving ? "Updating..." : "Update Password"}
                    </Button>
                  </div>
                </Form>
              </TabPane>

              {/* Notifications Settings 
              <TabPane
                tab={
                  <span>
                    <BellOutlined /> Notifications
                  </span>
                }
                key="notifications"
              >
                <Title level={4}>Notification Preferences</Title>
                <Text type="secondary" className="block mb-4">
                  Manage how you receive notifications and alerts
                </Text>

                <Form layout="vertical">
                  <Form.Item
                    label="Email Notifications"
                    name="email_notifications"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch defaultChecked />
                  </Form.Item>

                  <Form.Item
                    label="Product Updates"
                    name="product_updates"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch defaultChecked />
                  </Form.Item>

                  <Form.Item
                    label="Security Alerts"
                    name="security_alerts"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch defaultChecked />
                  </Form.Item>

                  <Text type="secondary" italic className="block mt-4">
                    Note: Critical system notifications cannot be disabled.
                  </Text>

                  <Divider />

                  <div className="flex justify-end">
                    <Button type="primary" icon={<SaveOutlined />}>
                      Save Preferences
                    </Button>
                  </div>
                </Form>
              </TabPane> */}
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
