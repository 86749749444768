import React, { useState, useEffect } from "react";
import {
  Dropdown,
  Button,
  Modal,
  message,
  Tabs,
  Card,
  Tag,
  Space,
  Typography,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import CreateTemplateModal from "../../components/ui/modal/CreateTemplateModal";
import {
  getSourcingTemplates,
  deleteTemplate,
} from "../../services/sourcingService";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import { set } from "react-hook-form";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const ManageSourcing = () => {
  const [templates, setTemplates] = useState([]);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [activeFilters, setActiveFilters] = useState({
    section: null,
  });
  const [availableSections, setAvailableSections] = useState([]);
  const [modalMode, setModalMode] = useState("create");

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const response = await getSourcingTemplates();
      setTemplates(response.data);
      // Extract unique sections and form types for filters
      const sections = [
        ...new Set(response.data.map((t) => t.attributes.section_key)),
      ];
      setAvailableSections(sections);
    } catch (error) {
      message.error("Failed to fetch templates");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (template) => {
    setSelectedTemplate(template);
    setModalMode("edit");
    setIsCreateModalVisible(true);
  };

  const handleDuplicate = (template) => {
    setSelectedTemplate({
      ...template,
      attributes: {
        ...template.attributes,
        name: `${template.attributes.name} (Copy)`,
      },
    });
    setModalMode("duplicate");
    setIsCreateModalVisible(true);
  };

  const handleDelete = async (templateId) => {
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this template?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteTemplate(templateId);
          message.success("Template deleted successfully");
          fetchTemplates();
        } catch (error) {
          message.error("Failed to delete template");
        }
      },
    });
  };

  const handleFilterSelect = (filterType, value) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const columns = [
    {
      title: "Template Name",
      dataIndex: ["attributes", "name"],
      key: "name",
      render: (name, record) => (
        <div>
          <Text strong>{name}</Text>
          <div>
            <Tag color="blue">{record.attributes.section_key}</Tag>
            {(record.attributes.active && <Tag color="green">Active</Tag>) || (
              <Tag color="red">Inactive</Tag>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Last Modified",
      dataIndex: ["attributes", "updated_at"],
      key: "updated_at",
      render: (date) => new Date(date).toLocaleDateString(),
      sorter: (a, b) =>
        new Date(a.attributes.updated_at) - new Date(b.attributes.updated_at),
    },
    /*{
      title: "Last Used",
      dataIndex: ["attributes", "last_used_at"],
      key: "last_used_at",
      render: (date) =>
        date ? new Date(date).toLocaleDateString() : "Never used",
      sorter: (a, b) => {
        if (!a.attributes.last_used_at) return 1;
        if (!b.attributes.last_used_at) return -1;
        return (
          new Date(a.attributes.last_used_at) -
          new Date(b.attributes.last_used_at)
        );
      },
    },*/
    {
      title: "Actions",
      key: "actions",
      width: 120,
      responsive: ["md"],
      render: (_, record) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button
            icon={<CopyOutlined />}
            onClick={() => handleDuplicate(record)}
          >
            Duplicate
          </Button>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const filteredTemplates = templates.filter((template) => {
    const searchLower = searchText.toLowerCase();
    const matchesSearch = template.attributes.name
      .toLowerCase()
      .includes(searchLower);
    const matchesTab =
      activeTab === "all" ||
      (activeTab === "active" && template.attributes.active) ||
      (activeTab === "inactive" && !template.attributes.active);

    // Section filter
    const matchesSection =
      !activeFilters.section ||
      activeFilters.section === template.attributes.section_key;

    return matchesSearch && matchesTab && matchesSection;
  });

  // Update the filter button to show active filters

  const filterMenu = {
    items: [
      {
        key: "section",
        label: "Filter by Section",
        children: availableSections.map((section) => ({
          key: section,
          label: section,
          onClick: () => handleFilterSelect("section", section),
        })),
      },
    ],
  };

  const renderFilterButton = () => {
    const activeFilterCount =
      Object.values(activeFilters).filter(Boolean).length;

    return (
      <Button icon={<FilterOutlined />}>
        Filters {activeFilterCount > 0 && `(${activeFilterCount})`}
      </Button>
    );
  };

  const renderTemplatePreview = () => {
    if (!selectedTemplate) return null;

    return (
      <Modal
        title={`Preview: ${selectedTemplate.attributes.name}`}
        open={isPreviewModalVisible}
        onCancel={() => setIsPreviewModalVisible(false)}
        width={800}
        footer={null}
      >
        <Card>
          <pre className="overflow-auto max-h-96">
            {JSON.stringify(selectedTemplate.attributes.template_data, null, 2)}
          </pre>
        </Card>
      </Modal>
    );
  };

  return (
    <div className="p-6">
      <div className="mb-4">
        <div className="flex justify-between items-center mb-2">
          <Title level={2}>Manage Sourcing Templates</Title>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsCreateModalVisible(true)}
          >
            Create Template
          </Button>
        </div>
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <TabPane tab="All Templates" key="all" />
          <TabPane tab="Active" key="active" />
          <TabPane tab="Inactive" key="inactive" />
        </Tabs>
        <div className="flex justify-between items-center mb-4">
          <Space size="large">
            <Dropdown menu={filterMenu} trigger={["click"]}>
              {renderFilterButton()}
            </Dropdown>
            {Object.entries(activeFilters).map(
              ([type, value]) =>
                value && (
                  <Tag
                    key={type}
                    closable
                    color="blue"
                    onClose={() => handleFilterSelect(type, null)}
                  >
                    {`${value}`}
                  </Tag>
                )
            )}
          </Space>
        </div>
      </div>

      <DataGrid columns={columns} data={filteredTemplates} onRowClick={null} />

      <CreateTemplateModal
        visible={isCreateModalVisible}
        onClose={() => {
          setIsCreateModalVisible(false);
          setSelectedTemplate(null);
          setModalMode("create");
        }}
        onSuccess={() => {
          setIsCreateModalVisible(false);
          setSelectedTemplate(null);
          setModalMode("create");
          fetchTemplates();
          message.success(
            `Template ${
              modalMode === "edit" ? "updated" : "created"
            } successfully`
          );
        }}
        mode={modalMode}
        initialTemplate={selectedTemplate}
      />

      {renderTemplatePreview()}
    </div>
  );
};

export default ManageSourcing;
