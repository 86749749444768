import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Table, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";

const DataGrid = ({
  columns,
  data,
  onRowClick,
  currentPage,
  pageSize,
  totalItems,
  onChangePage,
  onChangePageSize,
  onChangeSort,
  onChangeFilter,
  serverSide = false,
  sortBy,
  sortDirection,
  loading,
  onSearch = () => {},
  searchValue = "",
  tableFilters = {},
}) => {
  const [searchText, setSearchText] = useState(searchValue?.toString() || "");
  const [tableParams, setTableParams] = useState({
    pagination: { current: 1, pageSize: 10 },
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const debouncedSearch = useCallback(
    _.debounce((value) => {
      onSearch(value);
    }, 500),
    [onSearch]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // handle Table onChange
  const handleTableChange = (pagination, filters, sorter) => {
    if (isProcessing) return;
    setIsProcessing(true);

    if (serverSide) {
      if (onChangePage) onChangePage(pagination.current);
      if (onChangePageSize) onChangePageSize(pagination.pageSize);

      if (onChangeSort) {
        let direction = null;
        if (sorter.order === "ascend") direction = "asc";
        else if (sorter.order === "descend") direction = "desc";

        // Get the sort field from the column's sortField property or dataIndex
        const sortField = sorter.column?.sortField || sorter.field;
        onChangeSort(sortField, direction);
      }

      if (onChangeFilter) {
        onChangeFilter(filters);
      }
    } else {
      setTableParams({
        pagination,
        filters,
        sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
        sortField: Array.isArray(sorter) ? undefined : sorter.field,
      });
    }

    setIsProcessing(false);
  };

  // Mark the sorted column
  const processedColumns = useMemo(() => {
    return columns.map((col) => {
      const newCol = { ...col };
      //console.log("TableFilters", tableFilters);
      //console.log("Col", col.dataIndex);
      // Apply the parent's tableFilters to show selected filters
      if (serverSide && tableFilters[col.dataIndex]) {
        newCol.filteredValue = tableFilters[col.dataIndex];
      }

      // Check both sortField and dataIndex for sorting
      const colSortField = col.sortField || col.dataIndex;
      if (colSortField === sortBy) {
        newCol.sortOrder =
          sortDirection === "asc"
            ? "ascend"
            : sortDirection === "desc"
            ? "descend"
            : null;
      }

      return newCol;
    });
  }, [columns, sortBy, sortDirection, tableFilters, serverSide]);

  // Only do client-side searching if not in server-side mode
  const filteredData = useMemo(() => {
    if (serverSide || !searchText) {
      return data;
    }
    return data.filter((row) => {
      if (row.attributes && row.attributes.name) {
        return row.attributes.name
          .toLowerCase()
          .includes(searchText.toLowerCase());
      }
      // Fallback: check other top-level string properties
      return Object.values(row).some(
        (cell) =>
          typeof cell === "string" &&
          cell.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }, [data, searchText, serverSide]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (serverSide) {
      debouncedSearch(value);
    }
  };

  return (
    <div>
      <div className="flex justify-end mb-2">
        <Input
          placeholder="Search..."
          onChange={handleSearch}
          value={searchText}
          style={{ width: 200 }}
          prefix={<SearchOutlined />}
          allowClear
          onClear={() => {
            setSearchText("");
            if (serverSide) {
              onSearch("");
            }
          }}
        />
      </div>

      <Table
        columns={processedColumns}
        loading={loading}
        dataSource={filteredData}
        onChange={handleTableChange}
        pagination={{
          ...(serverSide
            ? {
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showSizeChanger: true,
                disabled: loading || isProcessing,
              }
            : tableParams.pagination),
          position: ["bottomRight"],
        }}
        rowKey="id"
        onRow={(record) => ({
          onClick: () => {
            if (onRowClick) onRowClick(record);
          },
        })}
      />
    </div>
  );
};

export default DataGrid;
