// src/components/supplier/SupplierInfoTab.js
import React from "react";
import InfoCard from "../../components/ui/cards/InfoCard";
import {
  HomeOutlined,
  GlobalOutlined,
  FileTextOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import StatusBadge from "../ui/badges/StatusBadge";

const SupplierInfoTab = ({ supplierData = {} }) => {
  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return "Never";
    return new Date(dateString).toLocaleString();
  };

  return (
    <InfoCard
      fields={[
        { name: "address", label: "Address", icon: <HomeOutlined /> },
        { name: "country", label: "Country", icon: <GlobalOutlined /> },
        { name: "tva_number", label: "TVA Number", icon: <FileTextOutlined /> },
        {
          name: "status",
          label: "Status",
          icon: <FileTextOutlined />,
          render: (value) => <StatusBadge status={value} />,
        },
        {
          name: "last_connection_date",
          label: "Last Connection Date",
          icon: <ClockCircleOutlined />,
          render: (value) => formatDate(value),
        },
        {
          name: "last_email_date",
          label: "Last Email Date",
          icon: <MailOutlined />,
          render: (value) => formatDate(value),
        },
        {
          name: "created_at",
          label: "Created At",
          icon: <CalendarOutlined />,
          render: (value) => formatDate(value),
        },
        {
          name: "updated_at",
          label: "Updated At",
          icon: <CalendarOutlined />,
          render: (value) => formatDate(value),
        },
      ]}
      data={supplierData}
    />
  );
};

export default SupplierInfoTab;
