import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../components/ui/PrivateRoute";
import Layout from "../components/ui/Layout";
import SupplierDashboard from "../features/supplier/SupplierDashboard";
import ProductDetailSupplier from "../features/supplier/ProductDetailSupplier";
import SupplierProductList from "../features/supplier/SupplierProductList";
import SupportForm from "../components/SupportForm";
import Settings from "../components/common/Settings";
import ManageSourcing from "../features/supplier/ManageSourcing";
import UserProfile from '../components/common/UserProfile';



const SupplierRoutes = () => (
  <PrivateRoute roles={["supplier"]}>
    <Layout>
      <Routes>
        <Route path="" element={<SupplierDashboard />} />
        <Route path="products/:id" element={<ProductDetailSupplier />} />
        <Route path="/products" element={<SupplierProductList />} />
        <Route path="/sourcing" element={<ManageSourcing />} />
        <Route path="/support" element={<SupportForm />} />
        <Route path="settings" element={<Settings />} />
        <Route path="profile" element={<UserProfile />} /> {/* Added route for brand's own profile */}
      </Routes>
    </Layout>
  </PrivateRoute>
);

export default SupplierRoutes;
