import api from "./api";

export const getSourcingTemplates = async () => {
  return api
    .get("/sourcing_templates")
    .then((response) => response.data);
};

export const createTemplate = async (templateData) => {
  return api
    .post("/sourcing_templates", templateData)
    .then((response) => response.data);
};

export const deleteTemplate = async (templateId) => {
  return api.delete(`/sourcing_templates/${templateId}`);
};

export const getAssignedForms = async (supplierId) => {
  return api
    .get("/suppliers/${supplierId}/form_templates")
    .then((response) => response.data);
};

export const updateTemplate = async (templateId, templateData) => {
  return api
    .put(`/sourcing_templates/${templateId}`, templateData)
    .then((response) => response.data);
}
