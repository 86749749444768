import React, { useEffect, useState } from "react";
import {
  getBrand,
  getBrandUsers,
  getBrandSuppliers,
  getBrandProducts,
} from "../../services/brandService";
import { useParams, useNavigate } from "react-router-dom";
import InfoCard from "../../components/ui/cards/InfoCard";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import StatusBadge from "../../components/ui/badges/StatusBadge";
import {
  message,
  Button,
  Flex,
  Tabs,
  Card,
  Image,
  Typography,
  Tag,
  Space,
  Skeleton,
} from "antd";
import {
  ShopOutlined,
  TeamOutlined,
  AppstoreOutlined,
  IdcardOutlined,
  GlobalOutlined,
  HomeOutlined,
  CalendarOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const BrandDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // State variables for data
  const [brand, setBrand] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [suppliersData, setSuppliersData] = useState([]);

  // Loading and error states
  const [loading, setLoading] = useState({
    brand: true,
    users: true,
    suppliers: true,
    products: true,
  });
  const [errors, setErrors] = useState({
    brand: null,
    users: null,
    suppliers: null,
    products: null,
  });

  // Pagination state for products
  const [productPage, setProductPage] = useState(1);
  const [productPageSize, setProductPageSize] = useState(10);
  const [productTotalCount, setProductTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [activeTabKey, setActiveTabKey] = useState("info");

  // Fetch data on component mount
  useEffect(() => {
    fetchBrandData();
    fetchBrandUsers();
    fetchBrandSuppliers();
  }, [id]);

  // Fetch products when pagination or search changes
  useEffect(() => {
    fetchBrandProducts();
  }, [id, productPage, productPageSize, searchValue]);

  // Fetch brand data with error handling
  const fetchBrandData = async () => {
    setLoading((prev) => ({ ...prev, brand: true }));
    try {
      const response = await getBrand(id);
      setBrand(response.data);
      setErrors((prev) => ({ ...prev, brand: null }));
    } catch (error) {
      console.error("Error fetching brand details", error);
      setErrors((prev) => ({
        ...prev,
        brand: "Failed to load brand information",
      }));
      message.error("Error loading brand information");
    } finally {
      setLoading((prev) => ({ ...prev, brand: false }));
    }
  };

  // Fetch brand users with error handling
  const fetchBrandUsers = async () => {
    setLoading((prev) => ({ ...prev, users: true }));
    try {
      const response = await getBrandUsers(id);
      setUsersData(response.data.data);
      setErrors((prev) => ({ ...prev, users: null }));
    } catch (error) {
      console.error("Error fetching brand users", error);
      setErrors((prev) => ({ ...prev, users: "Failed to load brand users" }));
      message.error("Error loading brand users");
    } finally {
      setLoading((prev) => ({ ...prev, users: false }));
    }
  };

  // Fetch brand suppliers with error handling
  const fetchBrandSuppliers = async () => {
    setLoading((prev) => ({ ...prev, suppliers: true }));
    try {
      const response = await getBrandSuppliers(id);
      setSuppliersData(response.data.data);
      setErrors((prev) => ({ ...prev, suppliers: null }));
    } catch (error) {
      console.error("Error fetching brand suppliers", error);
      setErrors((prev) => ({
        ...prev,
        suppliers: "Failed to load brand suppliers",
      }));
      message.error("Error loading brand suppliers");
    } finally {
      setLoading((prev) => ({ ...prev, suppliers: false }));
    }
  };

  // Fetch brand products with error handling
  const fetchBrandProducts = async () => {
    setLoading((prev) => ({ ...prev, products: true }));
    try {
      const response = await getBrandProducts(id, {
        page: productPage,
        per_page: productPageSize,
        search: searchValue,
      });

      setProductsData(response.data.data.data);

      if (response.data.meta && response.data.meta.count) {
        setProductTotalCount(response.data.meta.count);
      }

      setErrors((prev) => ({ ...prev, products: null }));
    } catch (error) {
      console.error("Error fetching brand products", error);
      setErrors((prev) => ({
        ...prev,
        products: "Failed to load brand products",
      }));
      message.error("Error loading brand products");
    } finally {
      setLoading((prev) => ({ ...prev, products: false }));
    }
  };

  // Navigation handlers
  const handleSupplierClick = (supplier) => {
    navigate(`/admin/suppliers/${supplier.id}`);
  };

  const handleProductClick = (product) => {
    navigate(`/admin/manage-products/${product.id}`);
  };

  const handleUserClick = (user) => {
    navigate(`/admin/users/${user.id}`);
  };

  const handleSearch = (newSearchValue) => {
    setProductPage(1);
    setSearchValue(newSearchValue);
  };

  // Check if the brand data is still loading
  if (loading.brand && !brand) {
    return (
      <div className="p-6">
        <Skeleton active paragraph={{ rows: 6 }} />
      </div>
    );
  }

  // Handle brand data load error
  if (errors.brand && !brand) {
    return (
      <div className="p-6">
        <Card>
          <Flex vertical align="center" gap="middle">
            <Text type="danger">{errors.brand}</Text>
            <Button type="primary" onClick={fetchBrandData}>
              Try Again
            </Button>
            <Button onClick={() => navigate(-1)}>Go Back</Button>
          </Flex>
        </Card>
      </div>
    );
  }

  // Extract brand data
  const brandData = brand?.data?.attributes || {};

  // Prepare data for tables
  const suppliers = suppliersData.map((supplier) => ({
    id: supplier.id,
    name: supplier.attributes.name,
    tva_number: supplier.attributes.tva_number,
    country: supplier.attributes.country,
    status: supplier.attributes.status,
  }));

  const products = productsData.map((product) => ({
    id: product.id,
    name: product.attributes.name,
    status: product.attributes.status,
    reference_brand: product.attributes.reference_brand,
    reference_supplier: product.attributes.reference_supplier,
    supplier:
      suppliersData.find(
        (supplier) => supplier.id === product.relationships?.supplier?.data?.id
      )?.attributes?.name || "N/A",
  }));

  const users = usersData.map((user) => ({
    id: user.id,
    name: user.attributes.name,
    email: user.attributes.email,
    role: user.attributes.role,
    status: user.attributes.invitation_status,
    first_name: user.attributes.first_name,
    last_login: user.attributes.last_sign_in_at,
  }));

  // Define columns for suppliers
  const columnsSupplier = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "TVA Number",
      dataIndex: "tva_number",
      key: "tva_number",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <StatusBadge status={text} />,
      sorter: true,
    },
  ];

  // Define columns for products
  const columnsProduct = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <StatusBadge status={text} />,
      sorter: true,
    },
    {
      title: "Reference Brand",
      dataIndex: "reference_brand",
      key: "reference_brand",
    },
    {
      title: "Reference Supplier",
      dataIndex: "reference_supplier",
      key: "reference_supplier",
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
    },
  ];

  // Define columns for users
  const columnsUser = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => (
        <Tag
          color={text === "admin" ? "red" : text === "brand" ? "blue" : "green"}
        >
          {text.toUpperCase()}
        </Tag>
      ),
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <StatusBadge status={text} />,
      sorter: true,
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      render: (text) => (text ? new Date(text).toLocaleString() : "Never"),
      sorter: true,
    },
  ];

  return (
    <div className="p-6">
      <Button onClick={() => navigate(-1)} className="mb-4">
        Back
      </Button>
      {/* Header with logo and title */}
      <Card className="mb-6">
        <Flex align="center" gap="large">
          {brandData.img_url ? (
            <Image
              src={brandData.img_url}
              alt={brandData.name}
              style={{ maxHeight: 120, maxWidth: 200, objectFit: "contain" }}
            />
          ) : (
            <div className="w-32 h-24 flex items-center justify-center bg-gray-100 rounded">
              <ShopOutlined style={{ fontSize: 40, color: "#d9d9d9" }} />
            </div>
          )}
          <div>
            <Title level={2}>{brandData.name}</Title>
            <Space size="large">
              <Text>
                <GlobalOutlined className="mr-1" /> {brandData.country || "N/A"}
              </Text>
              {brandData.active_license_types &&
                brandData.active_license_types.map((license) => (
                  <Tag color="green" key={license}>
                    {license}
                  </Tag>
                ))}
              <Text>
                <CalendarOutlined className="mr-1" /> Created:{" "}
                {new Date(brandData.created_at).toLocaleDateString()}
              </Text>
            </Space>
          </div>
        </Flex>
      </Card>

      {/* Tabs for different sections */}
      <Card>
        <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
          <TabPane
            tab={
              <span>
                <ShopOutlined className="mr-1" />
                Brand Information
              </span>
            }
            key="info"
          >
            {/* Brand Information Card */}
            <InfoCard
              fields={[
                { name: "address", label: "Address", icon: <HomeOutlined /> },
                { name: "country", label: "Country", icon: <GlobalOutlined /> },
                {
                  name: "active_license_types",
                  label: "Active Licenses",
                  icon: <FileTextOutlined />,
                  render: (value) => (
                    <Space size={[0, 8]} wrap>
                      {value.map((license) => (
                        <Tag color="green" key={license}>
                          {license}
                        </Tag>
                      ))}
                    </Space>
                  ),
                },
                {
                  name: "created_at",
                  label: "Created At",
                  icon: <CalendarOutlined />,
                  render: (value) => new Date(value).toLocaleDateString(),
                },
                {
                  name: "updated_at",
                  label: "Updated At",
                  icon: <CalendarOutlined />,
                  render: (value) => new Date(value).toLocaleDateString(),
                },
              ]}
              data={brandData}
            />
          </TabPane>

          <TabPane
            tab={
              <span>
                <IdcardOutlined className="mr-1" />
                Suppliers ({loading.suppliers ? "..." : suppliers.length})
              </span>
            }
            key="suppliers"
          >
            {/* Suppliers Section */}
            <DataGrid
              columns={columnsSupplier}
              data={suppliers}
              onRowClick={handleSupplierClick}
              loading={loading.suppliers}
              error={errors.suppliers}
              onRetry={fetchBrandSuppliers}
            />
          </TabPane>

          <TabPane
            tab={
              <span>
                <TeamOutlined className="mr-1" />
                Users ({loading.users ? "..." : users.length})
              </span>
            }
            key="users"
          >
            {/* Users Section */}
            <DataGrid
              columns={columnsUser}
              data={users}
              onRowClick={handleUserClick}
              loading={loading.users}
              error={errors.users}
              onRetry={fetchBrandUsers}
            />
          </TabPane>

          <TabPane
            tab={
              <span>
                <AppstoreOutlined className="mr-1" />
                Products ({loading.products ? "..." : productTotalCount})
              </span>
            }
            key="products"
          >
            {/* Products Section */}
            <DataGrid
              columns={columnsProduct}
              data={products}
              onRowClick={handleProductClick}
              serverSide={true}
              currentPage={productPage}
              pageSize={productPageSize}
              totalItems={productTotalCount}
              onChangePage={(newPage) => setProductPage(newPage)}
              onChangePageSize={(newPageSize) =>
                setProductPageSize(newPageSize)
              }
              searchValue={searchValue}
              onSearch={handleSearch}
              loading={loading.products}
              error={errors.products}
              onRetry={fetchBrandProducts}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default BrandDetail;
