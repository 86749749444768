// utils/fieldRenderers.js
import React from "react";
import { Input, InputNumber, Select, Checkbox, Slider } from "antd";

export const renderField = (fieldSchema, value, onChange, options = {}) => {
  if (!fieldSchema) return null;
  const { disabled, readOnly } = options;

  const isGroupHeader = (value) => {
    return (
      typeof value === "string" &&
      value.startsWith("=====") &&
      value.endsWith("=====")
    );
  };

  const commonProps = {
    disabled: disabled || readOnly,
    style: {
      width: "100%",
      backgroundColor: readOnly ? "#f5f5f5" : undefined,
      cursor: readOnly ? "not-allowed" : undefined,
    },
  };

  switch (fieldSchema.type.toLowerCase()) {
    case "string":
      if (fieldSchema.enum) {
        const processedOptions = fieldSchema.enum.map((opt) => ({
          value: opt,
          label: opt,
          disabled: isGroupHeader(opt),
        }));

        return (
          <Select
            {...commonProps}
            showSearch
            value={value}
            onChange={onChange}
            options={processedOptions}
            allowClear
          />
        );
      }

      return (
        <Input
          {...commonProps}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      );

    case "number":
      return <InputNumber {...commonProps} value={value} onChange={onChange} />;

    case "float":
      return (
        <InputNumber
          {...commonProps}
          value={value}
          onChange={onChange}
          // Convert dot -> comma for display
          formatter={(val) => (val ? val.toString().replace(".", ",") : "")}
          // Convert comma -> dot internally
          parser={(val) => (val ? val.replace(",", ".") : "")}
          step={0.01}
        />
      );

    case "boolean":
      return (
        <Checkbox
          {...commonProps}
          checked={value === true || value === "yes"}
          onChange={(e) => onChange(e.target.checked ? "yes" : "no")}
        />
      );

    case "date":
      return (
        <Input
          {...commonProps}
          type="date"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      );
    case "slider":
      const currentValue =
        value !== undefined && value !== null
          ? value
          : fieldSchema.defaultValue;

      return (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <Slider
              {...commonProps}
              min={fieldSchema.minimum || 0}
              max={fieldSchema.maximum || 100}
              step={fieldSchema.step || 1}
              value={currentValue}
              onChange={(newValue) => {
                // Ensure we're passing the numeric value, not a string
                onChange(
                  typeof newValue === "string" ? parseFloat(newValue) : newValue
                );
              }}
              tooltip={{
                formatter: (val) => `${val}${fieldSchema.unit || ""}`,
              }}
              style={{ flex: 1, marginRight: "15px" }}
            />
            <InputNumber
              min={fieldSchema.minimum || 0}
              max={fieldSchema.maximum || 100}
              step={fieldSchema.step || 1}
              value={currentValue}
              onChange={
                // Ensure we're passing the numeric value, not a string
                (newValue) => {
                  onChange(
                    typeof newValue === "string"
                      ? parseFloat(newValue)
                      : newValue
                  );
                }
              }
              disabled={disabled || readOnly}
              addonAfter={fieldSchema.unit}
              style={{ width: "100px" }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              {fieldSchema.minimum || 0}
              {fieldSchema.unit}
            </span>
            <span>
              {fieldSchema.maximum || 100}
              {fieldSchema.unit}
            </span>
          </div>
        </div>
      );
    case "array":
      if (fieldSchema.items?.enum) {
        return (
          <Select
            {...commonProps}
            showSearch
            mode="multiple"
            value={value}
            onChange={onChange}
            options={fieldSchema.items.enum.map((opt) => ({
              value: opt,
              label: opt,
            }))}
          />
        );
      }
      return null;

    default:
      return null;
  }
};
