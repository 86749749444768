// src/components/common/profile/ActivityTab.js
import React from "react";
import { Card } from "antd";
import EmptyState from "./EmptyState";

const ActivityTab = () => {
  return (
    <Card>
      <div className="py-4">
        <EmptyState
          title="No Recent Activity"
          description="User activity history will be displayed here"
        />
      </div>
    </Card>
  );
};

export default ActivityTab;
