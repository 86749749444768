// src/components/common/profile/UserProfileInfo.js
import React from "react";
import { Link,useNavigate } from "react-router-dom";
import { Descriptions, Tag, Typography } from "antd";
import {
  UserOutlined,
  MailOutlined,
  ShopOutlined,
  IdcardOutlined,
  ClockCircleOutlined,
  GlobalOutlined,
  PhoneOutlined,
  CommentOutlined,
} from "@ant-design/icons";
const { Text } = Typography;

const UserProfileInfo = ({ attributes, adminView, currentUser }) => {
  const navigate = useNavigate();

  const navigateToBrand = (brandId) => {
    navigate(`/${currentUser.role}/brands/${brandId}`);
  };
  return (
    <Descriptions
      title="User Information"
      layout="vertical"
      column={{ xs: 1, sm: 2, md: 3 }}
      bordered
    >
      <Descriptions.Item label="Full Name" span={2}>
        <UserOutlined className="mr-2" />
        {attributes.name}
      </Descriptions.Item>
      <Descriptions.Item label="First Name">
        {attributes.first_name || "Not set"}
      </Descriptions.Item>
      <Descriptions.Item label="Email">
        <MailOutlined className="mr-2" />
        {attributes.email}
      </Descriptions.Item>
      <Descriptions.Item label="Phone">
        <PhoneOutlined className="mr-2" />
        {attributes.phone || "Not set"}
      </Descriptions.Item>

      {attributes.role === "brand" && attributes.brand && (
        <Descriptions.Item label="Brand" span={3}>
          <ShopOutlined className="mr-2" />
          {adminView && attributes.brand_id ? (
            <Link
              to={`/${currentUser.attributes?.role}/brands/${attributes.brand_id}`}
            >
              {attributes.brand.name}
            </Link>
          ) : (
            attributes.brand.name
          )}
          <div className="mt-2">
            <Text type="secondary">
              <GlobalOutlined className="mr-2" />
              {attributes.brand.country}
            </Text>
          </div>
          {attributes.brand.address && (
            <div>
              <Text type="secondary">{attributes.brand.address}</Text>
            </div>
          )}
        </Descriptions.Item>
      )}

      {attributes.role === "supplier" && attributes.supplier && (
        <>
          <Descriptions.Item label="Supplier" span={3}>
            <IdcardOutlined className="mr-2" />
            {adminView && attributes.supplier_id ? (
              <Link
                to={`/${currentUser.role}/suppliers/${attributes.supplier_id}`}
              >
                {attributes.supplier.name}
              </Link>
            ) : (
              attributes.supplier.name
            )}
            <div className="mt-2">
              <Text type="secondary">
                <GlobalOutlined className="mr-2" />
                {attributes.supplier.country}
              </Text>
            </div>
            {attributes.supplier.address && (
              <div>
                <Text type="secondary">{attributes.supplier.address}</Text>
              </div>
            )}
            {adminView && attributes.supplier.status && (
              <div className="mt-2">
                <Tag
                  color={
                    attributes.supplier.status === "active" ? "green" : "orange"
                  }
                >
                  {attributes.supplier.status.toUpperCase()}
                </Tag>
              </div>
            )}
          </Descriptions.Item>

          {attributes.supplier &&
            attributes.supplier.brands &&
            attributes.supplier.brands.length > 0 && (
              <Descriptions.Item label="Associated Brands" span={3}>
                {attributes.supplier.brands.map((brand) => (
                  <Tag
                    color="blue"
                    key={brand.id}
                    className="mb-1 mr-1"
                    style={{ cursor: adminView ? "pointer" : "default" }}
                    onClick={
                      adminView ? () => navigateToBrand(brand.id) : undefined
                    }
                  >
                    <ShopOutlined className="mr-1" />
                    {brand.name} ({brand.country})
                  </Tag>
                ))}
              </Descriptions.Item>
            )}
        </>
      )}

      {attributes.comment && (
        <Descriptions.Item label="Comments" span={3}>
          <CommentOutlined className="mr-2" />
          {attributes.comment}
        </Descriptions.Item>
      )}

      <Descriptions.Item label="Account Created">
        <ClockCircleOutlined className="mr-2" />
        {new Date(attributes.created_at).toLocaleDateString()}
      </Descriptions.Item>
      <Descriptions.Item label="Last Sign In">
        <ClockCircleOutlined className="mr-2" />
        {attributes.last_sign_in_at
          ? new Date(attributes.last_sign_in_at).toLocaleString()
          : "Never"}
      </Descriptions.Item>
      <Descriptions.Item label="Sign In Count">
        {attributes.sign_in_count || 0}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default UserProfileInfo;
