import api from "./api";

/*
1. User details

{
    "data": [
        {
            "id": "8cea4b7b-a7bd-42a5-b770-12ba891ee13e",
            "type": "user",
            "attributes": {
                "name": "Azta",
                "first_name": "Azta",
                "email": "contact@aztasa.com",
                "phone": "0000000000",
                "comment": null,
                "role": "supplier",
                "invitation_status": "accepted",
                "id": "8cea4b7b-a7bd-42a5-b770-12ba891ee13e",
                "force_password_change": false,
                "brand_id": null,
                "supplier_id": "fd968e3b-1b8e-40fb-b50e-76e02821a7fd",
                "last_sign_in_at": "2025-03-05T15:35:51.864Z",
                "last_sign_in_ip": "::1",
                "current_sign_in_at": "2025-03-05T15:35:52.059Z",
                "current_sign_in_ip": "::1",
                "sign_in_count": 890,
                "brand": null,
                "supplier": {
                    "name": "AZTA SA",
                    "address": "Adresse du fournisseur",
                    "country": "France",
                    "tva_number": "FR3256223642498",
                    "status": "active",
                    "code_supplier": null,
                    "brands": [
                        {
                            "id": "dd050f06-6b42-41d8-80ad-44b4730cef9b",
                            "name": "Coopérative U",
                            "country": "France"
                        }
                    ],
                    "created_at": "2025-02-24T15:59:43Z",
                    "updated_at": "2025-02-28T08:35:00Z",
                    "status_details": null,
                    "last_connection_date": "2025-03-05T15:35:51Z",
                    "last_email_date": "2025-03-06T09:58:22Z",
                    "supplier_users": null,
                    "recent_emails": null
                },
                "created_at": "2025-02-24T15:59:43Z",
                "updated_at": "2025-03-05T15:35:52Z"
            }
        }
    ]
}
### 2. Get Email History

```
GET /api/v1/suppliers/:id/email_history
```

Response:
```json
{
  "data": [
    {
      "id": "e1f2g3h4-i5j6-7890-klmn-opqrstuvwxyz",
      "type": "user_email",
      "attributes": {
        "subject": "Please complete your product data",
        "sent_at": "2024-11-10T09:45:11Z",
        "formatted_sent_at": "10 November 2024, 09:45",
        "status": "delivered",
        "recipient": {
          "id": "a1b2c3d4-e5f6-7890-abcd-1234567890ab",
          "email": "supplier-user@example.com",
          "name": "John Doe"
        },
        "sender": {
                    "id": "a289b302-947b-4600-b77a-b5bb60f5fde3",
                    "email": "admin@example.com",
                    "name": "System Admin"
                },
      }
    }
  ]
}
```

### 3. Send Email to Supplier User

```
POST /api/v1/suppliers/:id/user_emails
```

Request Body:
```json
{
  "user_email": {
    "recipient_id": "a1b2c3d4-e5f6-7890-abcd-1234567890ab",
    "cc_list": "person1@example.com, person2@example.com",
    "subject": "Please complete your product data",
    "body": "Dear supplier,\n\nWe noticed that you haven't completed the data for your products. Please log in to the platform and complete the required information.\n\nThank you,\nAdmin"
  }
}
```

Response:
```json
{
  "data": {
    "id": "e1f2g3h4-i5j6-7890-klmn-opqrstuvwxyz",
    "type": "user_email",
    "attributes": {
      "subject": "Please complete your product data",
      "sent_at": "2024-11-18T22:45:11Z",
      "formatted_sent_at": "18 November 2024, 22:45",
      "status": "sent",
      "recipient": {
        "id": "a1b2c3d4-e5f6-7890-abcd-1234567890ab",
        "email": "supplier-user@example.com",
        "name": "John Doe"
      }, "sender": {
                    "id": "a289b302-947b-4600-b77a-b5bb60f5fde3",
                    "email": "admin@example.com",
                    "name": "System Admin"
                },
    }
  }
}
*/

export const getEmailHistory = (supplierId) => {
  return api.get(`/suppliers/${supplierId}/email_history`);
}

export const sendEmailToSupplierUser = (supplierId, emailData) => {
  return api.post(`/suppliers/${supplierId}/user_emails`, { user_email: emailData });
}
