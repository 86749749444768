import React, { useEffect, useState } from "react";
import { Modal, List, Button, message } from "antd";
import { getSourcingTemplates } from "../../../services/sourcingService";

const SourcingTemplateModal = ({ visible, onCancel, onSelect, sectionKey }) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      fetchTemplates();
    }
  }, [visible]);

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const response = await getSourcingTemplates(); // API call
      setTemplates(
        response.data.filter((t) => t.attributes.section_key === sectionKey && t.attributes.active)
      );
    } catch (error) {
      console.error("Error fetching templates:", error);
      message.error("Failed to load sourcing templates");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Select Sourcing Template"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <List
        loading={loading}
        dataSource={templates}
        renderItem={(template) => (
          <List.Item
            actions={[
              <Button type="primary" onClick={() => onSelect(template)}>
                Use Template
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={template.attributes.name}
              description={`Last updated: ${new Date(
                template.attributes.updated_at
              ).toLocaleDateString()}`}
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default SourcingTemplateModal;
