// src/components/supplier/EmailHistoryTab.js
import React from "react";
import { Table, Typography, Button, Skeleton, Tag } from "antd";
import { MailOutlined, EyeOutlined, RetweetOutlined } from "@ant-design/icons";
import { Modal, Space, Tooltip } from "antd";
import { useState } from "react";

const { Title, Text } = Typography;

const EmailHistoryTab = ({
  loading,
  error,
  emailHistory = [],
  onRetry,
  onResendEmail,
}) => {
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(null);
  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return "Never";
    return new Date(dateString).toLocaleString();
  };

  const handleViewEmail = (email) => {
    setCurrentEmail(email);
    setViewModalVisible(true);
  };

  const handleCloseViewModal = () => {
    setViewModalVisible(false);
    setCurrentEmail(null);
  };

  const handleResendEmail = (email) => {
    // Pass the email data to the parent component to prepopulate the email form
    onResendEmail({
      subject: email.attributes.subject,
      body:
        "---- Original message sent on " +
        formatDate(email.attributes.sent_at) +
        " ----\n\n" +
        (email.attributes.body || "No content available"),
      recipientId: email.attributes.recipient?.id,
    });
  };

  // Email history columns
  const emailHistoryColumns = [
    {
      title: "Date",
      dataIndex: "attributes",
      key: "sent_at",
      render: (attributes) => formatDate(attributes.sent_at),
      sorter: (a, b) =>
        new Date(b.attributes.sent_at) - new Date(a.attributes.sent_at),
      defaultSortOrder: "ascend",
    },
    {
      title: "Subject",
      dataIndex: "attributes",
      key: "subject",
      render: (attributes) => attributes.subject,
    },
    {
      title: "Recipient",
      dataIndex: "attributes",
      key: "recipient",
      render: (attributes) => (
        <span>
          {attributes.recipient?.name} ({attributes.recipient?.email})
        </span>
      ),
    },
    {
      title: "Sender",
      dataIndex: "attributes",
      key: "sender",
      render: (attributes) => (
        <span>
          {attributes.sender?.name} ({attributes.sender?.email})
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "attributes",
      key: "status",
      render: (attributes) => (
        <Tag color={attributes.status === "delivered" ? "green" : "blue"}>
          {attributes.status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Tooltip title="View Email Content">
            <Button
              icon={<EyeOutlined />}
              onClick={() => handleViewEmail(record)}
              size="small"
            />
          </Tooltip>
          <Tooltip title="Resend as New Email">
            <Button
              type="primary"
              icon={<RetweetOutlined />}
              onClick={() => handleResendEmail(record)}
              size="small"
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  if (loading) {
    return <Skeleton active paragraph={{ rows: 5 }} />;
  }

  if (error) {
    return (
      <div className="text-center py-4">
        <Text type="danger">{error}</Text>
        <div className="mt-2">
          <Button type="primary" onClick={onRetry}>
            Try Again
          </Button>
        </div>
      </div>
    );
  }

  if (emailHistory.length === 0) {
    return (
      <div className="text-center py-8">
        <MailOutlined style={{ fontSize: 40, color: "#d9d9d9" }} />
        <Title level={4} className="mt-4">
          No Email History
        </Title>
        <Text type="secondary">
          No emails have been sent to this supplier yet.
        </Text>
      </div>
    );
  }

  return (
    <>
      <Table
        dataSource={emailHistory}
        columns={emailHistoryColumns}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
      {viewModalVisible && currentEmail && (
        <Modal
          title={
            <div>
              <MailOutlined className="mr-2" />
              {currentEmail.attributes.subject}
            </div>
          }
          open={viewModalVisible}
          onCancel={handleCloseViewModal}
          footer={[
            <Button key="close" onClick={handleCloseViewModal}>
              Close
            </Button>,
            <Button
              key="resend"
              type="primary"
              icon={<RetweetOutlined />}
              onClick={() => {
                handleResendEmail(currentEmail);
                handleCloseViewModal();
              }}
            >
              Use as Template for New Email
            </Button>,
          ]}
          width={700}
        >
          <div className="mb-4">
            <p>
              <strong>Date:</strong>{" "}
              {formatDate(currentEmail.attributes.sent_at)}
            </p>
            <p>
              <strong>From:</strong> {currentEmail.attributes.sender?.name} (
              {currentEmail.attributes.sender?.email})
            </p>
            <p>
              <strong>To:</strong> {currentEmail.attributes.recipient?.name} (
              {currentEmail.attributes.recipient?.email})
            </p>
            <p>
              <strong>Status:</strong>{" "}
              <Tag
                color={
                  currentEmail.attributes.status === "delivered"
                    ? "green"
                    : "blue"
                }
              >
                {currentEmail.attributes.status.toUpperCase()}
              </Tag>
            </p>
          </div>
          <div className="border rounded p-4 bg-gray-50">
            <pre style={{ whiteSpace: "pre-wrap", fontFamily: "inherit" }}>
              {currentEmail.attributes.body || "No content available"}
            </pre>
          </div>
        </Modal>
      )}
    </>
  );
};

export default EmailHistoryTab;
