import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Modal, Form, Select, Checkbox, Button, Spin, Alert } from "antd";
import { FaDownload, FaCheckCircle } from "react-icons/fa";
import { useManageProducts } from "../../../hooks/products/useManageProducts";

const { Option } = Select;

const ExportEntityModal = ({
  visible,
  onClose,
  brands,
  suppliers,
  onExport,
}) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState("form");
  const [errorMessage, setErrorMessage] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const [exportBlob, setExportBlob] = useState(null);
  const [exportFilename, setExportFilename] = useState("export.csv");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Products pagination state
  const [accumulatedProducts, setAccumulatedProducts] = useState([]);
  const [exportCurrentPage, setExportCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Selection states
  const [selectedStates, setSelectedStates] = useState({
    brands: { all: false, selected: [] },
    suppliers: { all: false, selected: [] },
    products: { all: false, selected: [] },
    dataTypes: { all: false, selected: [] },
  });

  const {
    products,
    loading,
    pageSize,
    filters,
    setFilters,
    setCurrentPage,
    metaProductDataIds,
    setProductDataIds,
  } = useManageProducts();

  useEffect(() => {
    if (visible) {
      setProductDataIds(true);
      resetFormState();
    }
  }, [visible, setProductDataIds]);

  // Reset products when filters change
  useEffect(() => {
    if (
      selectedStates.brands.selected.length ||
      selectedStates.suppliers.selected.length
    ) {
      setAccumulatedProducts([]);
      setExportCurrentPage(1);
      setHasMore(true);
      setFilters({
        ...filters,
        brand_name: selectedStates.brands.selected,
        supplier_name: selectedStates.suppliers.selected,
        product_data_statuses: ["submitted", "forced_submitted", "accepted"],
      });
      setCurrentPage(1);
    }
  }, [selectedStates.brands.selected, selectedStates.suppliers.selected]);

  // Accumulate products
  useEffect(() => {
    if (products.length > 0) {
      setAccumulatedProducts((prev) => {
        const newProducts =
          exportCurrentPage === 1 ? products : [...prev, ...products];
        return Array.from(
          new Map(newProducts.map((item) => [item.id, item])).values()
        );
      });
      setHasMore(products.length === pageSize);
    }
  }, [products, exportCurrentPage, pageSize]);

  // Update filters when selections change

  const handleSelectChange = (type, isAll, values = []) => {
    setSelectedStates((prev) => ({
      ...prev,
      [type]: {
        all: isAll,
        selected: isAll
          ? type === "products"
            ? accumulatedProducts.map((p) => p.id)
            : type === "brands"
            ? brands.map((b) => b.id)
            : type === "suppliers"
            ? suppliers.map((s) => s.id)
            : []
          : values,
      },
    }));
  };

  const handleProductSelectScroll = useCallback(
    (e) => {
      const { target } = e;
      if (
        !loading &&
        hasMore &&
        target.scrollTop + target.offsetHeight >= target.scrollHeight - 20
      ) {
        setExportCurrentPage((prev) => prev + 1);
        setCurrentPage((prev) => prev + 1);
      }
    },
    [loading, hasMore, setCurrentPage]
  );

  const handleExportClick = () => {
    setIsExporting(true);
    setErrorMessage("");

    // Transform metaProductDataIds to only include the ID (second element)
    const productIdsOnly = metaProductDataIds.map(
      ([dataType, productId]) => productId
    );
    onExport({ productDataIds: productIdsOnly })
      .then(({ blob, filename }) => {
        setExportBlob(blob);
        setExportFilename(filename);
        setStep("success");
      })
      .catch((err) => setErrorMessage(`Export failed: ${err.message || err}`))
      .finally(() => setIsExporting(false));
  };

  const handleDownloadClick = () => {
    if (!exportBlob) return;
    const url = URL.createObjectURL(exportBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = exportFilename;
    link.click();
    URL.revokeObjectURL(url);
  };

  const resetFormState = () => {
    setStep("form");
    setErrorMessage("");
    setIsExporting(false);
    setExportBlob(null);
    setExportFilename("export.csv");
    setAccumulatedProducts([]);
    setExportCurrentPage(1);
    setHasMore(true);
    setSelectedStates({
      brands: { all: false, selected: [] },
      suppliers: { all: false, selected: [] },
      products: { all: false, selected: [] },
      dataTypes: { all: false, selected: [] },
    });
  };

  const derivedDataTypes = useMemo(() => {
    console.log(metaProductDataIds);
    const dataTypes = new Set();
    metaProductDataIds.forEach((productData) => {
      dataTypes.add(productData[0]);
    });
    return Array.from(dataTypes).map((dt) => ({
      id: dt,
      attributes: { name: dt },
    }));
  }, [metaProductDataIds]);

  const availableSuppliers = selectedStates.brands.all
    ? suppliers
    : suppliers.filter((supplier) =>
        supplier.attributes.brands.some((b) =>
          selectedStates.brands.selected.includes(b.id)
        )
      );

  const renderSelectField = (type, options, placeholder) => (
    <Form.Item label={type.charAt(0).toUpperCase() + type.slice(1)}>
      <Checkbox
        checked={selectedStates[type].all}
        onChange={(e) => handleSelectChange(type, e.target.checked)}
      >
        Select All {type.charAt(0).toUpperCase() + type.slice(1)}
      </Checkbox>
      {!selectedStates[type].all && (
        <Select
          mode="multiple"
          value={selectedStates[type].selected}
          onChange={(vals) => handleSelectChange(type, false, vals)}
          style={{ width: "100%", marginTop: 8 }}
          placeholder={placeholder}
          {...(type === "products" && {
            onPopupScroll: handleProductSelectScroll,
            onDropdownVisibleChange: setDropdownOpen,
            loading: loading && dropdownOpen,
            filterOption: false,
            onSearch: (value) => {
              setFilters((prev) => ({ ...prev, search: value }));
              setExportCurrentPage(1);
              setCurrentPage(1);
              setAccumulatedProducts([]);
            },
          })}
        >
          {(type === "products" ? accumulatedProducts : options).map((item) => (
            <Option key={item.id} value={item.id}>
              {item.attributes?.name}
            </Option>
          ))}
          {type === "products" && loading && dropdownOpen && (
            <Option disabled key="loading">
              Loading more...
            </Option>
          )}
        </Select>
      )}
    </Form.Item>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      title={step === "form" ? "Export Data" : "Export Complete"}
      destroyOnClose
    >
      <Spin spinning={isExporting && step === "form"}>
        <Form layout="vertical" form={form}>
          {step === "form" ? (
            <>
              {errorMessage && (
                <Alert
                  type="error"
                  message={errorMessage}
                  closable
                  showIcon
                  className="mb-4"
                />
              )}
              {renderSelectField("brands", brands, "Select brand(s)")}
              {renderSelectField(
                "suppliers",
                availableSuppliers,
                "Select supplier(s)"
              )}
              {renderSelectField(
                "products",
                accumulatedProducts,
                "Select product(s)"
              )}
              {renderSelectField(
                "dataTypes",
                derivedDataTypes,
                "Select data type(s)"
              )}

              <div className="text-right mt-4">
                <Button className="mr-2" onClick={resetFormState}>
                  Reset
                </Button>
                <Button className="mr-2" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  icon={<FaDownload />}
                  onClick={handleExportClick}
                  loading={isExporting}
                >
                  Export
                </Button>
              </div>
            </>
          ) : (
            <div className="text-center">
              <div className="flex justify-center mb-4">
                <FaCheckCircle className="text-green-500 text-4xl" />
              </div>
              <h2 className="mb-4">Export Successful!</h2>
              <p>Your CSV is ready. Click "Download" to save it.</p>
              <p className="italic">{exportFilename}</p>
              <div className="mt-6">
                <Button
                  type="primary"
                  onClick={handleDownloadClick}
                  icon={<FaDownload />}
                >
                  Download
                </Button>
                <Button className="ml-3" onClick={onClose}>
                  Close
                </Button>
              </div>
            </div>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default ExportEntityModal;
