// src/components/common/profile/SecurityTab.js
import React from "react";
import { Card, Typography, Tag, Button } from "antd";
import { LockOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const SecurityTab = ({ attributes, adminView, getInvitationStatusBadge, goToSettings }) => {
  return (
    <Card>
      <div>
        <Title level={5}>Account Status</Title>
        <div className="mb-4">
          <div className="flex items-center mb-2">
            <Tag color={attributes.force_password_change ? "red" : "green"}>
              {attributes.force_password_change
                ? "Password Change Required"
                : "Password Status OK"}
            </Tag>
          </div>
          {adminView && (
            <div className="flex items-center">
              <Text>Invitation Status: </Text>
              <div className="ml-2">
                {getInvitationStatusBadge(attributes.invitation_status)}
              </div>
            </div>
          )}
        </div>

        {attributes.last_sign_in_ip && (
          <div className="mt-4">
            <Title level={5}>Last Sign In Details</Title>
            <Text>IP Address: {attributes.last_sign_in_ip}</Text>
            <div>
              <Text>Time: {new Date(attributes.last_sign_in_at).toLocaleString()}</Text>
            </div>
          </div>
        )}

        {!adminView && (
          <div className="mt-4">
            <Button type="primary" icon={<LockOutlined />} onClick={goToSettings}>
              Change Password
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default SecurityTab;