// src/api/notificationService.js
import api from './api';

export const getNotifications = () => {
  return api.get('/notifications')
    .then(response => response.data);
};

export const getUnreadCount = () => {
  return api.get('/notifications/unread_count')
    .then(response => response.data.count);
};

export const markAsRead = (id) => {
  return api.post(`/notifications/${id}/mark_as_read`);
};

export const markAllAsRead = () => {
  return api.post('/notifications/mark_all_as_read');
};