// src/components/layout/Header.js
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { Layout, Avatar, Dropdown, Menu, Typography, Tag } from "antd";
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import NotificationBell from "../ui/NotificationBell";

const { Header: AntHeader } = Layout;
const { Text } = Typography;
const logo = require("../../assets/images/cc/logo-crystalcheck.png");

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
  };

  const handleSettings = () => {
    navigate(`/${user.role}/settings`);
  };

  const handleProfile = () => {
    navigate(`/${user.role}/profile`);
  };

  // Get user initials for avatar
  const getInitials = () => {
    if (!user?.name) return "";

    const nameParts = user.name.split(" ");
    if (nameParts.length >= 2) {
      return `${nameParts[0][0]}${
        nameParts[nameParts.length - 1][0]
      }`.toUpperCase();
    }
    return user.name.substring(0, 2).toUpperCase();
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="profile" onClick={handleProfile}>
        <div className="px-4 py-2 border-b border-gray-100">
          <div className="font-medium">{user?.name || "User"}</div>
          <div className="text-xs text-gray-500">{user?.email || ""}</div>
          <Tag className="text-xs mt-1 text-blue-600">
            {user?.role?.charAt(0).toUpperCase() + user?.role?.slice(1) ||
              "User"}
          </Tag>
        </div>
      </Menu.Item>
      <Menu.Item
        key="settings"
        icon={<SettingOutlined />}
        onClick={handleSettings}
      >
        Settings
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        onClick={handleLogout}
        danger
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <AntHeader className="bg-white px-6 py-0 flex items-center justify-between shadow">
      {/* Logo (Left) */}
      <div className="flex-1 flex items-center">
        <Link to={`/${user?.role || ""}`}>
          <img src={logo} alt="Logo" className="h-14 w-auto" />
        </Link>
      </div>

      {/* Center Title */}
      <div className="flex-1 flex justify-center">
        <Link to={`/${user?.role || ""}`} className="text-3xl font-bold">
          Crystal<span className="bg-blue-600 text-white px-2">Collect</span>
        </Link>
      </div>

      {/* Right Section */}
      <div className="flex-1 flex justify-end items-center space-x-4">
        <NotificationBell role={user?.role || ""} />

        {user && (
          <Dropdown
            overlay={userMenu}
            placement="bottomRight"
            trigger={["click"]}
          >
            <div className="flex items-center gap-2 cursor-pointer hover:bg-gray-50 rounded-full py-1 px-1 pr-3">
              <Avatar
                size="default"
                style={{
                  backgroundColor: user.avatarColor || "#1890ff",
                }}
              >
                {user.avatarUrl ? null : getInitials()}
              </Avatar>
              <Text className="hidden md:inline-block">
                {user?.name || "User"}
              </Text>
            </div>
          </Dropdown>
        )}
      </div>
    </AntHeader>
  );
};

export default Header;
