// src/components/ui/cards/EntityCard.js
import React from "react";
import { Card, Button, Dropdown, Menu, Checkbox, Tooltip } from "antd";
import { DownOutlined } from "@ant-design/icons";

const EntityCard = ({
  entity,
  fields,
  actions,
  onSelect,
  selected,
  statusField,
  StatusBadgeComponent,
  onTitleClick,
}) => {
  const { attributes } = entity;

  const renderField = (field) => {
    const value = attributes[field.name];
    if (field.render) {
      return (
        <div key={field.name} className="mb-2 text-sm">
          <strong>{field.label}:</strong> {field.render(value, attributes)}
        </div>
      );
    }
    if (value !== undefined && value !== null) {
      return (
        <div key={field.name} className="mb-2 text-sm">
          <strong>{field.label}:</strong> {value}
        </div>
      );
    }
    return null;
  };

  // Limit the number of visible actions
  const MAX_VISIBLE_ACTIONS = 3;
  const visibleActions = actions.slice(0, MAX_VISIBLE_ACTIONS);
  const moreActions = actions.slice(MAX_VISIBLE_ACTIONS);

  // Menu for additional actions
  const moreMenu = (
    <Menu>
      {moreActions.map((action, index) => (
        <Menu.Item key={index} onClick={() => action.onClick(entity)}>
          {action.icon && <span className="mr-2">{action.icon}</span>}
          {action.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  // Render visible action buttons
  const actionButtons = visibleActions.map((action, index) => (
    <Tooltip title={action.label} key={index}>
      <span
        onClick={() => action.onClick(entity)}
        aria-label={action.label}
        className="flex items-center justify-center p-2 cursor-pointer"
      >
        {action.icon}
      </span>
    </Tooltip>
  ));

  // Render the "More" dropdown if there are additional actions
  const moreDropdown = moreActions.length > 0 && (
    <Dropdown overlay={moreMenu} trigger={["click"]}>
      <Button
        size="small"
        type="text"
        icon={<DownOutlined />}
        aria-label="More actions"
      />
    </Dropdown>
  );

  // Combine all action elements
  const allActions = [...actionButtons, moreDropdown].filter(Boolean);

  const renderStatus = () => {
    let statusValue = attributes[statusField];
    if (
      statusValue === undefined ||
      statusValue === null ||
      !StatusBadgeComponent
    )
      return null;

    // Convert boolean status to string
    if (typeof statusValue === "boolean") {
      statusValue = statusValue ? "active" : "inactive";
    }
    return <StatusBadgeComponent status={statusValue} />;
  };

  return (
    <Card
      className="w-full h-full flex flex-col"
      title={
        <div className="h-12 flex items-center">
          <span
            onClick={() => onTitleClick && onTitleClick(entity.id)}
            className="cursor-pointer text-lg font-semibold text-gray-800 line-clamp-2"
          >
            {attributes.name}
          </span>
        </div>
      }
      extra={
        <Checkbox
          checked={selected?.includes(entity.id)}
          onChange={() => onSelect(entity.id)}
          aria-label="Select entity"
        />
      }
      cover={
        attributes.img_url ? (
          <div className="h-38 w-full overflow-hidden flex items-center justify-center p-2">
            <img
              alt={attributes.name}
              src={attributes.img_url}
              className="object-contain max-h-full max-w-full"
            />
          </div>
        ) : (
          <div className="h-1 w-full" /> // Placeholder div to maintain consistent height
        )
      }
      actions={allActions}
      bodyStyle={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
    >
      {/* Content */}
      <div className="p-2 flex flex-col justify-between h-full">
        <div className="overflow-hidden">
          {fields.map((field) => renderField(field))}
          {statusField && renderStatus()}
        </div>
      </div>
    </Card>
  );
};

export default EntityCard;