import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { Layout, Menu, Button } from "antd";
import {
  DashboardOutlined,
  ShopOutlined,
  IdcardOutlined,
  AppstoreOutlined,
  FormOutlined,
  TeamOutlined,
  UserAddOutlined,
  FileSearchOutlined,
  CustomerServiceOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import SupportForm from "../SupportForm";

const { Sider } = Layout;

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  // State to manage collapsed state
  const [collapsed, setCollapsed] = useState(false);
  // State to manage SupportForm modal visibility
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  // State to track the current selected menu item
  const [selectedKey, setSelectedKey] = useState("");

  const openSupportModal = () => {
    setIsSupportModalOpen(true);
  };

  const closeSupportModal = () => {
    setIsSupportModalOpen(false);
  };

  // Define menu items based on user role with parent path information
  const getMenuItems = () => {
    const items = [
      {
        key: `/${user.attributes?.role || user.role}`,
        icon: <DashboardOutlined />,
        label: "Dashboard",
        path: `/${user.attributes?.role || user.role}`,
        parentPath: null,
      },
    ];

    const role = user.attributes?.role || user.role;

    if (role === "admin") {
      items.push(
        {
          key: "/admin/manage-brands",
          icon: <ShopOutlined />,
          label: "Manage Brands",
          path: "/admin/manage-brands",
          parentPath: "/admin/manage-brands",
          childPaths: ["/admin/brands/"], // Child path prefix
        },
        {
          key: "/admin/manage-suppliers",
          icon: <IdcardOutlined />,
          label: "Manage Suppliers",
          path: "/admin/manage-suppliers",
          parentPath: "/admin/manage-suppliers",
          childPaths: ["/admin/suppliers/"], // Child path prefix
        },
        {
          key: "/admin/manage-products",
          icon: <AppstoreOutlined />,
          label: "Manage Products",
          path: "/admin/manage-products",
          parentPath: "/admin/manage-products",
          childPaths: ["/admin/manage-products/"], // Child path prefix
        },
        {
          key: "/admin/manage-forms",
          icon: <FormOutlined />,
          label: "Manage Forms",
          path: "/admin/manage-forms",
          parentPath: "/admin/manage-forms",
          childPaths: ["/admin/forms/"], // Child path prefix
        },
        {
          key: "/admin/manage-users",
          icon: <TeamOutlined />,
          label: "Manage Users",
          path: "/admin/manage-users",
          parentPath: "/admin/manage-users",
          childPaths: ["/admin/users/"], // Child path prefix
        }
      );
    } else if (role === "brand") {
      items.push(
        {
          key: "/brand/add-supplier",
          icon: <UserAddOutlined />,
          label: "Suppliers",
          path: "/brand/add-supplier",
          parentPath: "/brand/add-supplier",
          childPaths: ["/brand/suppliers/"],
        },
        {
          key: "/brand/add-product",
          icon: <AppstoreOutlined />,
          label: "Products",
          path: "/brand/add-product",
          parentPath: "/brand/add-product",
          childPaths: ["/brand/products/"],
        }
      );
    } else if (role === "supplier") {
      items.push(
        {
          key: "/supplier/products",
          icon: <AppstoreOutlined />,
          label: "Products",
          path: "/supplier/products",
          parentPath: "/supplier/products",
          childPaths: ["/supplier/products/"],
        },
        {
          key: "/supplier/sourcing",
          icon: <FileSearchOutlined />,
          label: "Sourcing",
          path: "/supplier/sourcing",
          parentPath: "/supplier/sourcing",
          childPaths: ["/supplier/sourcing/"],
        },
        {
          key: "support",
          icon: <CustomerServiceOutlined />,
          label: "Support",
          path: null,
          parentPath: null,
        }
      );
    }

    return items;
  };

  const menuItems = getMenuItems();

  // Improved function to determine the selected menu item
  const findSelectedKey = (pathname) => {
    // First, check for exact matches
    const exactMatch = menuItems.find(item => item.path === pathname);
    if (exactMatch) return exactMatch.key;

    // Next, check if the current path is a child path of any menu item
    for (const item of menuItems) {
      if (item.childPaths) {
        for (const childPath of item.childPaths) {
          if (pathname.startsWith(childPath)) {
            return item.key;
          }
        }
      }
    }

    // If no matches, use the longest matching prefix
    let matchedKey = "";
    let maxLength = 0;
    
    menuItems.forEach((item) => {
      if (
        pathname.startsWith(item.key) &&
        item.key.length > maxLength
      ) {
        matchedKey = item.key;
        maxLength = item.key.length;
      }
    });
    
    return matchedKey;
  };

  // Update selected key when location changes
  useEffect(() => {
    const key = findSelectedKey(location.pathname);
    setSelectedKey(key);
  }, [location.pathname]);

  return (
    <>
      <Sider
        width={220}
        className="site-layout-background"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        breakpoint="lg"
        collapsedWidth="80"
        trigger={null} // Hide default trigger
      >
        {/* Custom collapse button */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "0 16px",
            background: "#fff",
          }}
        >
          <Button
            type="text"
            onClick={() => setCollapsed(!collapsed)}
            style={{ background: "#fff", border: "none" }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </div>

        <Menu
          mode="inline"
          selectedKeys={[selectedKey]}
          style={{ height: "100%", borderRight: 0 }}
        >
          {menuItems.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              title={item.label}
              onClick={item.key === "support" ? openSupportModal : undefined}
            >
              {item.key !== "support" ? (
                <Link to={item.path}>
                  {!collapsed && <span>{item.label}</span>}
                </Link>
              ) : (
                <span onClick={openSupportModal} style={{ cursor: "pointer" }}>
                  {!collapsed && <span>{item.label}</span>}
                </span>
              )}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>

      {/* SupportForm Modal */}
      {isSupportModalOpen && <SupportForm onClose={closeSupportModal} />}
    </>
  );
};

export default Sidebar;