import React from "react";
import { FaTrash } from "react-icons/fa";
import { Modal } from "antd";

const { confirm } = Modal;

const ActionButtons = ({ row, onCheck, onDelete }) => {
  const status = row.status.toLowerCase();
  let mainActionLabel = "";
  let isDisabled = false;

  // Determine the label and disabled state based on status
  switch (status) {
    case "submitted":
    case "forced_submitted":
      mainActionLabel = "Review Data";
      break;
    case "in_progress":
      mainActionLabel = "Check Data";
      break;
    case "to_complete":
      mainActionLabel = "View Details";
      isDisabled = false;
      break;
    case "accepted":
    case "refused":
      mainActionLabel = "View Details";
      isDisabled = false;
      break;
    default:
      mainActionLabel = "View Details";
      isDisabled = false;
  }

  // Function to show the antd confirmation modal for delete
  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure you want to delete this record?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDelete(row);
      },
    });
  };

  return (
    <div className="flex space-x-2">
      <button
        onClick={() => onCheck(row)}
        disabled={isDisabled}
        className={`px-3 py-1 rounded ${
          isDisabled
            ? "bg-gray-300 cursor-not-allowed"
            : "bg-blue-500 text-white hover:bg-blue-600"
        }`}
      >
        {mainActionLabel}
      </button>
      <button
        onClick={showDeleteConfirm}
        className="px-3 py-1 rounded bg-red-500 text-white hover:bg-red-600"
      >
        <FaTrash />
      </button>
    </div>
  );
};

export default ActionButtons;
