// src/components/ui/form/EntityForm.js

import React from "react";
import { Modal, Form, Input, Select, Checkbox, Button } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const EntityForm = ({
  onSubmit,
  isOpen,
  onClose,
  fields,
  entityName,
  form,
  onValuesChange,
}) => {
  const handleFinish = (values) => {
    onSubmit(values);
    //form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={isOpen}
      title={
        form.getFieldValue("id")
          ? `Edit ${entityName}`
          : `Create New ${entityName}`
      }
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onValuesChange={onValuesChange}
      >
        {fields.map((field) => {
          const commonProps = {
            key: field.name,
            name: field.name,
            label: field.label,
            rules: [
              {
                required: field.required,
                message: `Please input ${field.label}`,
              },
              ...(field.rules || []),
            ],
            preserve: true, // Preserve field values
          };
          switch (field.type) {
            case "textarea":
              return (
                <Form.Item {...commonProps}>
                  <TextArea rows={field.rows || 4} />
                </Form.Item>
              );
            case "checkbox":
              return (
                <Form.Item {...commonProps} valuePropName="checked">
                  <Checkbox>{field.label}</Checkbox>
                </Form.Item>
              );
            case "select":
              return (
                <Form.Item {...commonProps}>
                  <Select placeholder={`Select ${field.label}`}>
                    {field.options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            case "multi-select":
              return (
                <Form.Item {...commonProps}>
                  <Select mode="multiple" placeholder={`Select ${field.label}`}>
                    {field.options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            case "array":
              return (
              <Form.List name={field.name} key={field.name}>
                {(fieldsList, { add, remove }) => (
                <div>
                  <label>{field.label}</label>
                  {fieldsList.map((fieldItem, index) => (
                  <Form.Item
                    key={fieldItem.key}
                    required={field.required}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Form.Item
                    {...fieldItem}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={field.children[0].rules}
                    noStyle
                    >
                    <Input
                      placeholder={field.children[0].label}
                      style={{ width: "90%" }}
                    />
                    </Form.Item>
                    <MinusCircleOutlined
                    className="dynamic-delete-button"
                    style={{ margin: "0 8px" }}
                    onClick={() => remove(fieldItem.name)}
                    />
                  </Form.Item>
                  ))}
                  <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add new {field.label}
                  </Button>
                  </Form.Item>
                </div>
                )}
              </Form.List>
              );

            default:
              return (
                <Form.Item {...commonProps}>
                  <Input
                    type={field.type || "text"}
                    disabled={field.disabled}
                  />
                </Form.Item>
              );
          }
        })}
        <Form.Item>
          <div className="flex justify-end">
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {form.getFieldValue("id") ? "Update" : "Create"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EntityForm;
