import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Form,
  Select,
  Input,
  message,
  Steps,
  Button,
  Spin,
  Alert,
  Space,
  Divider,
  Card,
  Typography,
} from "antd";
import {
  getAssignedForms,
  createTemplate,
  updateTemplate,
} from "../../../services/sourcingService";
import FormBuilder from "../datagrid/FormBuilder";
import { AuthContext } from "../../../contexts/AuthContext";

const { Step } = Steps;
const { Text } = Typography;

const CreateTemplateModal = ({
  visible,
  onClose,
  onSuccess,
  mode = "create",
  initialTemplate = null,
}) => {
  const [form] = Form.useForm();
  const { user } = useContext(AuthContext);
  const [sourcingName, setSourcingName] = useState("");
  const [sourcingStatus, setSourcingStatus] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [assignedForms, setAssignedForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [templateData, setTemplateData] = useState(null);
  const [newTemplateData, setNewTemplateData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [fetchingForms, setFetchingForms] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (visible && user?.supplier_id) {
      fetchAssignedForms(user.supplier_id);
    }
  }, [visible, user?.supplier_id]);

  useEffect(() => {
    if (!visible) {
      resetModal();
    }
  }, [visible]);

  useEffect(() => {
    if (
      visible &&
      initialTemplate &&
      (mode === "edit" || mode === "duplicate")
    ) {
      const formTemplateId =
        initialTemplate.relationships.form_template.data.id;
      // Wait for assigned forms to be loaded
      if (assignedForms.length > 0) {
        const matchingForm = assignedForms.find((f) => f.id === formTemplateId);
        if (matchingForm) {
          setSelectedForm(matchingForm);
          setSelectedSection(initialTemplate.attributes.section_key);
          setSourcingName(initialTemplate.attributes.name);
          setSourcingStatus(initialTemplate.attributes.active);
          const templateArray =
            initialTemplate.attributes.template_data[
              initialTemplate.attributes.section_key
            ];
          // if flat form, use the whole template data
          if (initialTemplate.attributes.section_key === "form") {
            setTemplateData(templateArray);
          } else {
            setTemplateData(templateArray.length > 0 ? templateArray[0] : {});
          }

          // Set form values after form is selected
          form.setFieldsValue({
            name: initialTemplate.attributes.name,
            formType: formTemplateId,
            section: initialTemplate.attributes.section_key,
            active: initialTemplate.attributes.active,
            formData:
              initialTemplate.attributes.template_data[
                initialTemplate.attributes.section_key
              ],
          });

          // go to the last step if all data is loaded and form mode is edit
          /*if (mode === "edit") {
            setCurrentStep(2);
          }*/
        }
      }
    }
  }, [visible, initialTemplate, mode, assignedForms]);

  const resetModal = () => {
    setCurrentStep(0);
    setSelectedForm(null);
    setSelectedSection(null);
    setTemplateData(null);
    setNewTemplateData(null);
    setError(null);
    form.resetFields();
  };

  const fetchAssignedForms = async (supplierId) => {
    setFetchingForms(true);
    setError(null);
    try {
      const response = await getAssignedForms(supplierId);
      const forms = response.data;
      setAssignedForms(forms);
    } catch (error) {
      setError("Failed to fetch assigned forms");
      message.error("Failed to fetch assigned forms");
    } finally {
      setFetchingForms(false);
    }
  };

  const handleFormSelect = (formId) => {
    const selectedForm = assignedForms.find((f) => f.id === formId);
    if (selectedForm) {
      setSelectedForm(selectedForm);
      setSelectedSection(null);
      form.setFieldsValue({ section: undefined, formData: undefined });
    }
  };

  const getSourcingSections = (form) => {
    if (!form?.attributes?.schema?.properties) return [];

    const schema = form.attributes.schema;

    // If the schema is a flat object (like a form), treat the whole schema as one section
    if (schema.type === "object" && !hasNestedObjects(schema.properties)) {
      return [
        {
          value: "form", // or any identifier you prefer
          label: schema.title || "Form Data",
          type: "object",
        },
      ];
    }

    // Original logic for nested schemas
    return Object.entries(schema.properties)
      .filter(([key, schema]) => {
        if (schema.type === "array") {
          return schema.items && schema.items.properties;
        }
        if (schema.type === "object") {
          return schema.properties;
        }
        return false;
      })
      .map(([key, schema]) => ({
        value: key,
        label: schema.title || key,
        type: schema.type,
      }));
  };

  // Helper function to check if an object has nested objects/arrays
  const hasNestedObjects = (properties) => {
    return Object.values(properties).some(
      (prop) => prop.type === "object" || prop.type === "array"
    );
  };

  const handleSectionSelect = (sectionKey) => {
    setSelectedSection(sectionKey);
    setTemplateData(null);
    setNewTemplateData(null);
    form.setFieldsValue({ formData: undefined });
  };

  const handleFormDataChange = (data) => {
    if (!selectedForm || !selectedSection) return;

    let formattedData = data;

    /*if (initialTemplate || templateData) {
      formattedData = {
        ...templateData,
        ...data,
      };
    } else {
      formattedData = data;
    }*/

    console.log("formattedData", formattedData);

    if (!formattedData) {
      return;
    }
    setNewTemplateData(formattedData);

    form.setFieldsValue({ formData: formattedData });
  };

  const handleBooleanChange = (templateData) => {
    //  add a helper method in your validator (or in your service/controller) that converts "yes" to true and "no" to false for fields expected to be boolean
    //  this is just a simple example, you can add more complex logic if needed
    return Object.keys(templateData).reduce((acc, key) => {
      if (templateData[key] === "yes") {
        acc[key] = true;
      } else if (templateData[key] === "no") {
        acc[key] = false;
      } else {
        acc[key] = templateData[key];
      }
      return acc;
    }, {});
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!templateData && !newTemplateData) {
        setError("Please fill in all required fields");
        setLoading(false);
        return;
      }

      const templateDataToSend = newTemplateData
        ? handleBooleanChange(newTemplateData)
        : handleBooleanChange(templateData);

      const templatePayload = {
        name: selectedForm.attributes.name + " - " + sourcingName,
        form_template_id: selectedForm.id,
        section_key: selectedSection,
        active: sourcingStatus,
        template_data: {
          [selectedSection]:
            selectedSection === "form"
              ? templateDataToSend
              : [templateDataToSend],
        },
      };

      if (mode === "edit") {
        templatePayload.form_template_id =
          initialTemplate.relationships.form_template.data.id;
        templatePayload.name =
          sourcingName !== initialTemplate.attributes.name
            ? sourcingName
            : initialTemplate.attributes.name;
        await updateTemplate(initialTemplate.id, templatePayload);
        //message.success("Template updated successfully");
      } else {
        await createTemplate(templatePayload);
        //message.success("Template created successfully");
      }
      onSuccess();
    } catch (error) {
      if (error.isAxiosError) {
        setError(error.response?.data?.message || "Failed to create template");
      } else {
        console.error(error);
        setError("Please fill in all required fields");
      }
    } finally {
      setLoading(false);
    }
  };

  // Update modal title based on mode
  const getModalTitle = () => {
    switch (mode) {
      case "edit":
        return "Edit Sourcing Template";
      case "duplicate":
        return "Duplicate Sourcing Template";
      default:
        return "Create Sourcing Template";
    }
  };

  const getSchemaForSection = () => {
    if (!selectedForm || !selectedSection) return null;

    // If flat form, return the whole schema
    if (selectedSection === "form") {
      return selectedForm.attributes.schema;
    }

    const schema = selectedForm.attributes.schema;
    const sectionSchema = schema.properties[selectedSection];

    if (sectionSchema.type === "array") {
      return sectionSchema.items;
    }
    return sectionSchema;
  };

  const steps = [
    {
      title: "Basic Info",
      content: (
        <Card className="mb-4">
          <Space direction="vertical" className="w-full">
            <Form.Item
              name="name"
              label="Template Name"
              rules={[
                { required: true, message: "Please enter a template name" },
              ]}
              onChange={(e) => setSourcingName(e.target.value)}
            >
              <Input placeholder="Enter a descriptive name for your template" />
            </Form.Item>

            <Form.Item
              name="formType"
              label="Form Type"
              rules={[{ required: true, message: "Please select a form type" }]}
              help="Select the form type to create a template from"
            >
              <Select
                loading={fetchingForms}
                options={assignedForms.map((form) => ({
                  value: form.id,
                  label: form.attributes.name,
                }))}
                onChange={handleFormSelect}
                placeholder="Select the form type"
              />
            </Form.Item>

            <Form.Item
              name="active"
              label="Active"
              rules={[
                { required: true, message: "Please select the active status" },
              ]}
              help="Select the active status of the template"
            >
              <Select
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                placeholder="Select the active status"
                defaultValue={true}
                onChange={(value) => setSourcingStatus(value)}
              />
            </Form.Item>
          </Space>
        </Card>
      ),
    },
    {
      title: "Select Section",
      content: (
        <Card className="mb-4">
          <Form.Item
            name="section"
            label="Section"
            rules={[{ required: true, message: "Please select a section" }]}
            help="Select the section you want to create a template for"
          >
            <Select
              options={getSourcingSections(selectedForm)}
              onChange={handleSectionSelect}
              placeholder="Select the section to template"
              disabled={!selectedForm}
            />
          </Form.Item>
        </Card>
      ),
    },
    {
      title: "Configure Template",
      content: selectedSection && selectedForm && (
        <Card className="mb-4">
          <Form.Item
            name="formData"
            label="Section Data"
            rules={[
              { required: true, message: "Please fill in the template data" },
            ]}
          >
            <div className="border rounded-md p-4">
              {/* Check if it's an array type and use the items schema */}
              <FormBuilder
                initialData={templateData}
                schema={getSchemaForSection()}
                uiSchema={
                  selectedSection === "form"
                    ? selectedForm.attributes?.ui_schema
                    : selectedForm.attributes?.ui_schema?.[selectedSection]
                }
                onChange={handleFormDataChange}
                mode="preview"
              />
            </div>
          </Form.Item>
        </Card>
      ),
    },
  ];

  const next = async () => {
    try {
      await form.validateFields(
        currentStep === 0
          ? ["name", "formType"]
          : currentStep === 1
          ? ["section"]
          : ["formData"]
      );
      setCurrentStep((current) => current + 1);
    } catch (error) {
      // Validation error handled by form
    }
  };

  const prev = () => {
    setCurrentStep((current) => current - 1);
  };

  return (
    <Modal
      title={getModalTitle()}
      open={visible}
      onCancel={onClose}
      width={1200}
      footer={null}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <div className="px-4 py-2">
          <Steps current={currentStep} className="mb-8">
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>

          {error && (
            <Alert
              message="Error"
              description={error}
              type="error"
              showIcon
              className="mb-4"
            />
          )}

          <Form
            form={form}
            layout="vertical"
            className="max-h-[60vh] overflow-y-auto px-4"
          >
            {steps[currentStep].content}
          </Form>

          <Divider />

          <div className="flex justify-end gap-2">
            {currentStep > 0 && <Button onClick={prev}>Previous</Button>}
            {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={next}>
                Next
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button type="primary" onClick={handleSubmit} loading={loading}>
                {mode === "edit" ? "Update" : "Create"} Template
              </Button>
            )}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default CreateTemplateModal;
