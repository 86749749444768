import React, { useState, useEffect, useContext, useRef } from "react";
import {
  MessageCircle,
  Send,
  ChevronRight,
  ChevronLeft,
  Clock,
} from "lucide-react";
import { AuthContext } from "../../../contexts/AuthContext";

const ActivityMessage = ({
  timestamp,
  user: messageUser,
  text,
  currentUser,
}) => {
  const formattedText = text.split("\n").map((line, i) => (
    <React.Fragment key={i}>
      {line}
      {i < text.split("\n").length - 1 && <br />}
    </React.Fragment>
  ));

  const isCurrentUser = currentUser?.name === messageUser;
  const displayName = isCurrentUser ? "You" : messageUser;

  return (
    <div className="relative pl-4 pb-4 group">
      {/* Activity indicator line */}
      <div className="absolute left-0 top-6 bottom-0 w-px bg-gray-200 group-last:hidden"></div>

      {/* Activity dot */}
      <div className="absolute left-0 top-1.5 w-2 h-2 rounded-full bg-blue-500 -translate-x-1/2"></div>

      <div className="mb-1">
        <span className="font-medium text-gray-900">{displayName}</span>
        <span className="text-gray-500 text-sm"> added a comment</span>
      </div>

      <div className="text-sm text-gray-700 bg-gray-50 rounded-lg p-3 border border-gray-100">
        <div className="whitespace-pre-wrap">{formattedText}</div>
      </div>

      <div className="flex items-center mt-1.5 text-xs text-gray-500">
        <Clock className="w-3.5 h-3.5 mr-1" />
        <span>{timestamp}</span>
      </div>
    </div>
  );
};

const ChatBox = ({ initialComments = "", onChatUpdate, mode="edit" }) => {
  const { user } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const messagesEndRef = useRef(null);

  // Parse multiline messages correctly
  const parseComments = (comments) => {
    const messages = [];
    let currentMessage = null;

    comments.split("\n").forEach((line) => {
      const regex = /^\[(.+?) - (.+?)\]:\s*(.*)$/;
      const match = line.match(regex);

      if (match) {
        if (currentMessage) {
          messages.push(currentMessage);
        }
        const [, timestamp, user, text] = match;
        currentMessage = { timestamp, user, text };
      } else if (currentMessage && line.trim()) {
        currentMessage.text += "\n" + line;
      } else if (line.trim()) {
        currentMessage = {
          timestamp: "",
          user: "Unknown",
          text: line,
        };
      }
    });

    if (currentMessage) {
      messages.push(currentMessage);
    }

    return messages;
  };

  useEffect(() => {
    if (initialComments) {
      const messages = parseComments(initialComments);
      setChatHistory(messages);
    }
  }, [initialComments]);

  // Scroll to bottom whenever chat history changes
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    setShowPopup(false);
  };

  const handleSend = () => {
    if (!newMessage.trim()) return;
    const timestamp = new Date().toLocaleString();
    const newMsgObj = {
      timestamp,
      user: user?.name || "Anonymous", // Use user's name from context
      text: newMessage.trim(),
    };
    const updatedHistory = [...chatHistory, newMsgObj];
    setChatHistory(updatedHistory);
    setNewMessage("");
    if (onChatUpdate) {
      const combined = updatedHistory
        .map((msg) => `[${msg.timestamp} - ${msg.user}]: ${msg.text}`)
        .join("\n");
      onChatUpdate(combined);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div
      className={`flex flex-col bg-white transition-all duration-300 ease-in-out ${
        collapsed ? "w-12" : "w-80"
      } h-full`}
    >
      {/* Header */}
      <div className="p-3 border-b border-gray-100 bg-white flex items-center justify-between">
        <div className="flex items-center gap-2 text-gray-700">
          <MessageCircle className="w-5 h-5 text-blue-500" />
          {!collapsed && <span className="font-semibold">Comments</span>}
        </div>
        <div className="relative">
          <button
            onClick={toggleCollapsed}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            onMouseEnter={() => setShowPopup(true)}
            onMouseLeave={() => setShowPopup(false)}
            style={{ border: "none", boxShadow: "none" }}
          >
            {collapsed ? (
              <ChevronLeft className="w-5 h-5 text-gray-600" />
            ) : (
              <ChevronRight className="w-5 h-5 text-gray-600" />
            )}
          </button>
          {/* Tooltip */}
          {collapsed && showPopup && (
            <div className="absolute top-1/2 right-full -translate-y-1/2 mr-2 px-2 py-1 bg-gray-800 text-white text-sm rounded whitespace-nowrap z-50">
              Expand comments
            </div>
          )}
        </div>
      </div>

      {/* Fixed layout structure with flex-col */}
      {!collapsed && (
        <div className="flex-1 overflow-y-auto p-3 space-y-4">
          {chatHistory.length === 0 ? (
            <div className="flex flex-col items-center justify-center text-gray-500 min-h-[14rem]">
              <MessageCircle className="w-12 h-12 mb-2 text-gray-300" />
              <p className="text-sm">No comments yet</p>
            </div>
          ) : (
            chatHistory.map((msg, index) => (
              <ActivityMessage key={index} {...msg} currentUser={user} />
            ))
          )}
        </div>
      )}

      {/* Fixed Chat Input */}
      {!collapsed && (
        <div
          className={`border-t border-gray-100 bg-white p-3 ${
            mode === "preview" ? "sticky bottom-[0rem]" : "sticky bottom-[3.8rem]"
          }`}
        >
          <div className="relative">
            <textarea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Leave a comment..."
              className="w-full px-3 py-2 pr-10 text-sm border border-gray-200 rounded-lg focus:outline-none focus:border-blue-500 resize-none h-20"
            />
            <button
              onClick={handleSend}
              className="absolute right-2 bottom-2 p-2 text-blue-500 hover:bg-blue-50 rounded-full transition-colors"
            >
              <Send className="w-5 h-5" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
