// src/components/admin/ManageSuppliers.js
import React, { useState, useEffect, useMemo } from "react";
import {
  getSuppliers,
  createSupplier,
  updateSupplier,
  deleteSupplier,
  importSuppliers,
} from "../../services/supplierService";
import { getBrands } from "../../services/brandService";
import EntityList from "../../components/ui/cards/EntityList";
import EntityForm from "../../components/ui/form/EntityForm";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Modal, message, Form } from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import StatusBadge from "../../components/ui/badges/StatusBadge";
import { FaPlus } from "react-icons/fa";
import ImportEntityModal from "../../components/ui/modal/ImportEntityModal";

const ManageSuppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [editingSupplier, setEditingSupplier] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    fetchSuppliers();
    fetchBrands();
  }, []);

  const fetchSuppliers = () => {
    getSuppliers()
      .then((response) => {
        if (response.data.data.length === 0) {
          message.info("No Suppliers found. Please add a Supplier first.");
          return;
        }
        // sort suppliers by creation date
        response.data.data.sort((a, b) => {
          return (
            new Date(b.attributes.updated_at) -
            new Date(a.attributes.updated_at)
          );
        });
        setSuppliers(response.data.data);
        const headers = Object.keys(response.data.data[0].attributes);
        setHeaders(headers);
      })
      .catch((error) => {
        console.error("Error fetching suppliers", error);
        message.error("Failed to fetch suppliers.");
      });
  };

  const fetchBrands = () => {
    getBrands()
      .then((response) => {
        setBrands(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching brands", error);
        message.error("Failed to fetch brands.");
      });
  };

  // Handle form values change to update state if needed
  const handleValuesChange = (changedValues, allValues) => {
    // Currently not handling dynamic fields based on other fields
  };

  // Define form fields
  const supplierFormFields = useMemo(
    () => [
      {
        label: "Name",
        name: "name",
        type: "text",
        required: true,
      },
      {
        label: "Address",
        name: "address",
        type: "text",
        required: false,
      },
      {
        label: "Country",
        name: "country",
        type: "text",
        required: true,
      },
      {
        label: "TVA Number OR Company ID",
        name: "tva_number",
        type: "text",
        required: true,
      },
      {
        name: "brand_ids",
        label: "Brand",
        type: "multi-select",
        options: brands.map((brand) => ({
          value: brand.id,
          label: brand.attributes.name,
        })),
        required: true,
        rules: [
          { required: true, message: "Please select at least one brand" },
        ],
      },
      {
        label: "Code supplier",
        name: "code_supplier",
        type: "text",
        required: false,
      },
    ],
    [brands]
  );

  // Handle form submission
  const handleFormSubmit = (values) => {
    // If there's an id, we assume user is editing
    if (editingSupplier) {
      updateSupplier(editingSupplier.id, values)
        .then(() => {
          message.success("Supplier updated successfully");
          resetForm(); // only reset on success
          fetchSuppliers();
        })
        .catch((error) => {
          console.error("Error updating supplier", error);

          let errorMessage =
            error.response?.data?.message || "Failed to update supplier";
          if (error.response?.data?.errors) {
            const errorsArray = error.response.data.errors;
            const combined = errorsArray.map((err) => err.message).join("; ");
            errorMessage = `${errorMessage}: ${combined}`;
          }

          message.error(errorMessage);
          // DO NOT reset form here so the user can correct it
        });
    } else {
      createSupplier(values)
        .then(() => {
          message.success("Supplier created successfully");
          resetForm(); // only reset on success
          fetchSuppliers();
        })
        .catch((error) => {
          console.error("Error creating supplier", error?.response?.data);

          let errorMessage =
            error.response?.data?.message || "Failed to create supplier";
          if (error.response?.data?.errors) {
            const errorsArray = error.response.data.errors;
            const combined = errorsArray.map((err) => err).join("; ");
            errorMessage = `${errorMessage}: ${combined}`;
          }

          message.error(errorMessage);
          // DO NOT reset form data here
        });
    }
  };

  // Reset form and close modal
  const resetForm = () => {
    form.resetFields();
    setEditingSupplier(null);
    setIsModalOpen(false);
  };

  // Open modal for editing a supplier
  const openModalForEditing = (supplier) => {
    setEditingSupplier(supplier);
    const initialValues = {
      id: supplier.id,
      ...supplier.attributes,
      brand_ids: supplier.attributes.brands.map((brand) => brand.id),
      code_supplier: supplier.attributes.code_supplier || "",
    };
    form.setFieldsValue(initialValues);
    setIsModalOpen(true);
  };

  // Handle edit action
  const handleEdit = (supplier) => {
    openModalForEditing(supplier);
  };

  // Handle select action
  const handleSelect = (supplierId) => {
    setSelectedSuppliers((prevSelected) =>
      prevSelected.includes(supplierId)
        ? prevSelected.filter((id) => id !== supplierId)
        : [...prevSelected, supplierId]
    );
  };

  // Handle select all action
  const handleSelectAll = () => {
    if (selectedSuppliers.length === suppliers.length) {
      setSelectedSuppliers([]);
    } else {
      setSelectedSuppliers(suppliers.map((supplier) => supplier.id));
    }
  };

  // Handle delete action
  const handleDelete = (supplierId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this supplier?",
      onOk: () => {
        deleteSupplier(supplierId)
          .then(() => {
            message.success("Supplier deleted successfully");
            fetchSuppliers();
          })
          .catch((error) => {
            let errorMessage =
              error.response?.data?.message || "Failed to delete supplier";
            if (error.response?.data?.errors) {
              const errorsArray = error.response.data.errors;
              const combined = errorsArray.map((err) => err).join("; ");
              errorMessage = `${errorMessage}: ${combined}`;
            }
            message.error(errorMessage);
          });
      },
    });
  };

  // Handle view details action
  const handleViewDetails = (supplierId) => {
    navigate(`/admin/suppliers/${supplierId}`);
  };

  // Define fields to display in the EntityCard
  const entityFields = [
    { name: "country", label: "Country" },
    { name: "tva_number", label: "TVA Number" },
    {
      name: "brands",
      label: "Brand",
      render: (brands) => {
        return brands.map((brand) => brand.name).join(", ");
      },
    },
    { label: "Code supplier", name: "code_supplier" },
  ];

  // Define actions for the EntityCard
  const actions = [
    {
      key: "edit",
      label: "Edit",
      icon: <EditOutlined />,
      onClick: handleEdit,
    },
    {
      key: "view",
      label: "View",
      icon: <EyeOutlined />,
      onClick: (entity) => handleViewDetails(entity.id),
    },
    {
      key: "delete",
      label: "Delete",
      icon: <DeleteOutlined />,
      onClick: (entity) => handleDelete(entity.id),
    },
  ];

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Manage Suppliers</h2>
        <div className="flex space-x-4">
          <Button
            onClick={() => {
              // "New" supplier => editingSupplier stays null
              setEditingSupplier(null);
              form.resetFields(); // clear form
              setIsModalOpen(true);
            }}
            type="primary"
            icon={<FaPlus />}
          >
            Add New Supplier
          </Button>
          <Button
            onClick={() => setIsImportModalOpen(true)}
            type="default"
            icon={<UploadOutlined />}
          >
            Import Suppliers (CSV)
          </Button>
        </div>
      </div>

      {/* Delete Selected Button */}
      <div className="mb-4">
        {selectedSuppliers.length > 0 && (
          <Button
            danger
            onClick={() => {
              Modal.confirm({
                title: "Are you sure you want to delete selected suppliers?",
                onOk: () => {
                  selectedSuppliers.forEach((id) => handleDelete(id));
                  setSelectedSuppliers([]);
                },
              });
            }}
          >
            Delete Selected
          </Button>
        )}
      </div>

      {/* Entity Form Modal */}
      <EntityForm
        onSubmit={handleFormSubmit}
        editingEntity={editingSupplier}
        isOpen={isModalOpen}
        onClose={resetForm}
        fields={supplierFormFields}
        entityName="Supplier"
        form={form}
        onValuesChange={handleValuesChange}
      />

      {/* Select All Checkbox */}
      <Checkbox
        checked={
          selectedSuppliers.length === suppliers.length && suppliers.length > 0
        }
        onChange={handleSelectAll}
        style={{ marginBottom: 16 }}
      >
        Select All
      </Checkbox>

      {/* Entity List */}
      <EntityList
        entities={suppliers}
        fields={entityFields}
        actions={actions}
        onSelect={handleSelect}
        selectedEntities={selectedSuppliers}
        statusField="status"
        StatusBadgeComponent={StatusBadge}
        searchBy={["name", "country", "tva_number"]}
        filters={[
          {
            name: "brands",
            label: "Brand",
            options: brands.map((brand) => ({
              value: brand.id,
              label: brand.attributes.name,
            })),
          },
          {
            name: "country",
            label: "Country",
            options: [
              ...Array.from(
                new Set(
                  suppliers.map((supplier) => supplier.attributes.country)
                )
              ).map((country) => ({
                value: country,
                label: country,
              })),
            ],
          },
          {
            name: "status",
            label: "Status",
            options: [
              ...Array.from(
                new Set(suppliers.map((supplier) => supplier.attributes.status))
              ).map((status) => ({
                value: status,
                label: status.charAt(0).toUpperCase() + status.slice(1),
              })),
            ],
          },
        ]}
        onTitleClick={handleViewDetails}
      />

      <ImportEntityModal
        visible={isImportModalOpen}
        onClose={() => setIsImportModalOpen(false)}
        onImportSuccess={() => fetchSuppliers()}
        importServiceFunction={importSuppliers}
        headers={headers}
        entityName="Suppliers"
      />
    </div>
  );
};

export default ManageSuppliers;
