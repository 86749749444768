// src/components/ui/modals/EmailModal.js
import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Space,
  Table,
  Typography,
  Tag,
  Tooltip,
} from "antd";
import {
  SendOutlined,
  MailOutlined,
  UserOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const { Title, Text } = Typography;

const EmailModal = ({
  visible,
  onCancel,
  onSend,
  users = [],
  loading,
  emailHistory = [],
  emailTemplate = null,
}) => {
  const [form] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectionStep, setSelectionStep] = useState(true);

  useEffect(() => {
    // If we have a template, set initial values and go directly to composition
    if (emailTemplate && visible) {
      // Find the user that matches the recipient ID
      const recipient = users.find(
        (user) => user.id === emailTemplate.recipientId
      );

      if (recipient) {
        setSelectedUser(recipient);
        setSelectionStep(false);

        // Set form values
        form.setFieldsValue({
          subject: `Re: ${emailTemplate.subject}`,
          body: emailTemplate.body,
        });
      }
    }
  }, [emailTemplate, visible, users]);

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return "Never";
    return new Date(dateString).toLocaleString();
  };

  // Find the last email sent to a user
  const getLastEmailForUser = (userId) => {
    const userEmails = emailHistory.filter(
      (email) => email.attributes?.recipient?.id === userId
    );

    if (userEmails.length === 0) return null;

    // Sort by date (newest first) and return the first one
    return userEmails.sort(
      (a, b) => new Date(b.attributes.sent_at) - new Date(a.attributes.sent_at)
    )[0];
  };

  // Handle user selection
  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setSelectionStep(false);
  };

  // Handle form submission
  const handleSend = (values) => {
    onSend({
      ...values,
      recipient_id: selectedUser.id,
    });
    resetModal();
  };

  // Reset the modal state
  const resetModal = () => {
    form.resetFields();
    setSelectedUser(null);
    setSelectionStep(true);
  };

  // Handle modal cancel
  const handleCancel = () => {
    resetModal();
    onCancel();
  };

  // Back to user selection
  const handleBack = () => {
    setSelectionStep(true);
  };

  // Define columns for user selection table
  const userColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <span>
          <UserOutlined className="mr-2" />
          {text}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <Tooltip title={text}>
          <span>
            <MailOutlined className="mr-2" />
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      render: (text) => (
        <span>
          <ClockCircleOutlined className="mr-2" />
          {formatDate(text)}
        </span>
      ),
      sorter: (a, b) => {
        if (!a.last_login && !b.last_login) return 0;
        if (!a.last_login) return 1;
        if (!b.last_login) return -1;
        return new Date(b.last_login) - new Date(a.last_login);
      },
      defaultSortOrder: "descend",
    },
    {
      title: "Last Email",
      key: "last_email",
      render: (_, record) => {
        const lastEmail = getLastEmailForUser(record.id);
        return lastEmail ? (
          <Tooltip title={lastEmail.attributes.subject}>
            <span>
              <MailOutlined className="mr-2" />
              {formatDate(lastEmail.attributes.sent_at)}
            </span>
          </Tooltip>
        ) : (
          <span>No emails sent</span>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => handleUserSelect(record)}
          size="small"
        >
          Select
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title={
        <div>
          <SendOutlined className="mr-2" />
          {selectionStep
            ? "Select User to Email"
            : `Send Email to ${selectedUser?.name}`}
        </div>
      }
      open={visible}
      onClose={resetModal}
      onCancel={handleCancel}
      footer={null}
      width={800}
    >
      {selectionStep ? (
        <div>
          <Text type="secondary" className="block mb-4">
            Select a user to send an email. You can see their last login time
            and last email received.
          </Text>
          <Table
            dataSource={users}
            columns={userColumns}
            rowKey="id"
            pagination={{ pageSize: 5 }}
            size="middle"
          />
        </div>
      ) : (
        <Form form={form} layout="vertical" onFinish={handleSend}>
          <Form.Item label="Recipient">
            <Input
              value={`${selectedUser?.name} (${selectedUser?.email})`}
              disabled
              prefix={<MailOutlined className="site-form-item-icon" />}
            />
          </Form.Item>

          <Form.Item
            name="cc"
            label="CC"
            rules={[
              {
                type: "string",
                validator: (_, value) => {
                  if (!value) return Promise.resolve();

                  // Split by comma and validate each email
                  const emails = value.split(",").map((email) => email.trim());
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                  const invalidEmails = emails.filter(
                    (email) => !emailRegex.test(email)
                  );

                  if (invalidEmails.length > 0) {
                    return Promise.reject(
                      `Invalid email(s): ${invalidEmails.join(", ")}`
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder="email1@example.com, email2@example.com"
              prefix={<MailOutlined className="site-form-item-icon" />}
            />
          </Form.Item>

          <Form.Item
            name="subject"
            label="Subject"
            rules={[{ required: true, message: "Please enter a subject" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="body"
            label="Message"
            rules={[{ required: true, message: "Please enter a message" }]}
          >
            <TextArea rows={6} placeholder="Type your message here..." />
          </Form.Item>

          <Form.Item className="text-right">
            <Space>
              <Button onClick={handleBack}>Back to User Selection</Button>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                <SendOutlined /> Send Email
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EmailModal;
