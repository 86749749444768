import React from "react";
import { Avatar, Typography, Tag, Badge, Button } from "antd";
import { UserOutlined, SettingOutlined } from "@ant-design/icons";

const { Title } = Typography;

const UserProfileAvatar = ({ user, adminView, getInitials, getRoleColor, getInvitationStatusBadge, goToSettings }) => {
  return (
    <div className="flex flex-col items-center md:w-1/4 p-4">
      <Avatar
        size={120}
        icon={<UserOutlined />}
        style={{ backgroundColor: "#1890ff" }}
      >
        {getInitials(user.name)}
      </Avatar>
      <Title level={4} className="mt-4 mb-1">
        {user.name}
      </Title>
      <div className="mb-2">
        <Tag color={getRoleColor(user.role)}>
          {user.role?.toUpperCase()}
        </Tag>
      </div>
      {adminView && (
        <div className="mb-4">
          {getInvitationStatusBadge(user.invitation_status)}
        </div>
      )}
      {!adminView && (
        <Button
          type="primary"
          icon={<SettingOutlined />}
          onClick={goToSettings}
          block
        >
          Account Settings
        </Button>
      )}
    </div>
  );
};

export default UserProfileAvatar;