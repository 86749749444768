// src/features/admin/SupplierDetail.js
import React, { useEffect, useState } from "react";
import {
  getSupplier,
  getSupplierUsers,
  getSupplierProducts,
} from "../../services/supplierService";
import {
  getEmailHistory,
  sendEmailToSupplierUser,
} from "../../services/mailService";
import { useParams, useNavigate } from "react-router-dom";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import StatusBadge from "../../components/ui/badges/StatusBadge";
import {
  Button,
  message,
  Flex,
  Tabs,
  Card,
  Typography,
  Tag,
  Space,
  Skeleton,
} from "antd";
import {
  ShopOutlined,
  TeamOutlined,
  AppstoreOutlined,
  IdcardOutlined,
  GlobalOutlined,
  CalendarOutlined,
  MailOutlined,
  HistoryOutlined,
  ClockCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import EmailModal from "../../components/ui/modal/EmailModal";
import EmailHistoryTab from "../../components/supplier/EmailHistoryTab";
import SupplierInfoTab from "../../components/supplier/SupplierInfoTab";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const SupplierDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // State variables for data
  const [supplier, setSupplier] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [emailHistory, setEmailHistory] = useState([]);

  // Loading and error states
  const [loading, setLoading] = useState({
    supplier: true,
    users: true,
    products: true,
    emailHistory: true,
  });
  const [errors, setErrors] = useState({
    supplier: null,
    users: null,
    products: null,
    emailHistory: null,
  });

  // Pagination state for products
  const [productPage, setProductPage] = useState(1);
  const [productPageSize, setProductPageSize] = useState(10);
  const [productTotalCount, setProductTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [activeTabKey, setActiveTabKey] = useState("info");

  // Email modal state
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState(null);

  // Fetch data on component mount
  useEffect(() => {
    fetchSupplierData();
    fetchSupplierUsers();
    fetchEmailHistory();
  }, [id]);

  // Fetch products when pagination or search changes
  useEffect(() => {
    fetchSupplierProducts();
  }, [id, productPage, productPageSize, searchValue]);

  // Fetch supplier data with error handling
  const fetchSupplierData = async () => {
    setLoading((prev) => ({ ...prev, supplier: true }));
    try {
      const response = await getSupplier(id);
      setSupplier(response.data);
      setBrandsData(response.data.data.attributes.brands || []);
      setErrors((prev) => ({ ...prev, supplier: null }));
    } catch (error) {
      console.error("Error fetching supplier details", error);
      setErrors((prev) => ({
        ...prev,
        supplier: "Failed to load supplier information",
      }));
      message.error("Error loading supplier information");
    } finally {
      setLoading((prev) => ({ ...prev, supplier: false }));
    }
  };

  // Fetch supplier users with error handling
  const fetchSupplierUsers = async () => {
    setLoading((prev) => ({ ...prev, users: true }));
    try {
      const response = await getSupplierUsers(id);
      setUsersData(response.data.data);
      setErrors((prev) => ({ ...prev, users: null }));
    } catch (error) {
      console.error("Error fetching supplier users", error);
      setErrors((prev) => ({
        ...prev,
        users: "Failed to load supplier users",
      }));
      message.error("Error loading supplier users");
    } finally {
      setLoading((prev) => ({ ...prev, users: false }));
    }
  };

  // Fetch email history with error handling
  const fetchEmailHistory = async () => {
    setLoading((prev) => ({ ...prev, emailHistory: true }));
    try {
      const response = await getEmailHistory(id);
      setEmailHistory(response.data.data || []);
      setErrors((prev) => ({ ...prev, emailHistory: null }));
    } catch (error) {
      console.error("Error fetching email history", error);
      setErrors((prev) => ({
        ...prev,
        emailHistory: "Failed to load email history",
      }));
      message.error("Error loading email history");
    } finally {
      setLoading((prev) => ({ ...prev, emailHistory: false }));
    }
  };

  // Fetch supplier products with error handling
  const fetchSupplierProducts = async () => {
    setLoading((prev) => ({ ...prev, products: true }));
    try {
      const response = await getSupplierProducts(id, {
        page: productPage,
        per_page: productPageSize,
        search: searchValue,
      });

      setProductsData(response.data.data.data);

      if (response.data.meta && response.data.meta.count) {
        setProductTotalCount(response.data.meta.count);
      }

      setErrors((prev) => ({ ...prev, products: null }));
    } catch (error) {
      console.error("Error fetching supplier products", error);
      setErrors((prev) => ({
        ...prev,
        products: "Failed to load supplier products",
      }));
      message.error("Error loading supplier products");
    } finally {
      setLoading((prev) => ({ ...prev, products: false }));
    }
  };

  // Email-related functions
  const openEmailModal = () => {
    setEmailModalVisible(true);
  };

  const closeEmailModal = () => {
    fetchEmailHistory();
    fetchSupplierData();
    setEmailModalVisible(false);
    setEmailTemplate(null);
  };

  const handleSendEmail = async (values) => {
    setSendingEmail(true);
    try {
      const emailData = {
        recipient_id: values.recipient_id,
        cc_list: values.cc || "",
        subject: values.subject,
        body: values.body,
      };

      await sendEmailToSupplierUser(id, emailData);
      message.success("Email sent successfully");
      closeEmailModal();

      // Refresh email history
      fetchEmailHistory();
    } catch (error) {
      console.error("Error sending email", error);
      message.error("Failed to send email. Please try again.");
    } finally {
      setSendingEmail(false);
    }
  };

  // Navigation handlers
  const handleBrandClick = (brand) => {
    navigate(`/admin/brands/${brand.id}`);
  };

  const handleProductClick = (product) => {
    navigate(`/admin/manage-products/${product.id}`);
  };

  const handleUserDetailsClick = (user) => {
    navigate(`/admin/users/${user.id}`);
  };

  const handleSearch = (newSearchValue) => {
    setProductPage(1);
    setSearchValue(newSearchValue);
  };

  const handleResendEmail = (template) => {
    setEmailTemplate(template);
    setEmailModalVisible(true);
  };

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return "Never";

    const formattedDate = new Date(dateString).toLocaleString();
    const daysSince = getDaysSince(dateString);

    return `${formattedDate} ${daysSince}`;
  };

  const getDaysSince = (dateString) => {
    if (!dateString) return "";

    const targetDate = new Date(dateString);
    const today = new Date();

    // Reset time to compare just dates
    targetDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const diffTime = today - targetDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return "(today)";
    if (diffDays === 1) return "(1 day ago)";
    return `(${diffDays} days ago)`;
  };

  // Check if the supplier data is still loading
  if (loading.supplier && !supplier) {
    return (
      <div className="p-6">
        <Skeleton active paragraph={{ rows: 6 }} />
      </div>
    );
  }

  // Handle supplier data load error
  if (errors.supplier && !supplier) {
    return (
      <div className="p-6">
        <Card>
          <Flex vertical align="center" gap="middle">
            <Text type="danger">{errors.supplier}</Text>
            <Button type="primary" onClick={fetchSupplierData}>
              Try Again
            </Button>
            <Button onClick={() => navigate(-1)}>Go Back</Button>
          </Flex>
        </Card>
      </div>
    );
  }

  // Extract supplier data
  const supplierData = supplier?.data?.attributes || {};

  // Prepare data for tables
  const brands = brandsData.map((brand) => ({
    id: brand.id,
    name: brand.name,
    country: brand.country,
  }));

  const products = productsData.map((product) => {
    const brandId = product.relationships?.brand?.data?.id;
    const brand = brandsData.find((b) => b.id === brandId);

    return {
      id: product.id,
      name: product.attributes.name,
      status: product.attributes.status,
      reference_brand: product.attributes.reference_brand,
      reference_supplier: product.attributes.reference_supplier,
      brand: brand ? brand.name : "N/A",
    };
  });

  const users = usersData.map((user) => ({
    id: user.id,
    name: user.attributes.name,
    email: user.attributes.email,
    role: user.attributes.role,
    status: user.attributes.invitation_status,
    last_login: user.attributes.last_sign_in_at,
  }));

  // Define columns for brands
  const columnsBrand = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleBrandClick(record);
            }}
            size="small"
          >
            View Details
          </Button>
        </Space>
      ),
    },
  ];

  // Define columns for products
  const columnsProduct = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <StatusBadge status={text} />,
      sorter: true,
    },
    {
      title: "Reference Brand",
      dataIndex: "reference_brand",
      key: "reference_brand",
    },
    {
      title: "Reference Supplier",
      dataIndex: "reference_supplier",
      key: "reference_supplier",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleProductClick(record);
            }}
            size="small"
          >
            View Details
          </Button>
        </Space>
      ),
    },
  ];

  // Define columns for users
  const columnsUser = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => (
        <Tag
          color={text === "admin" ? "red" : text === "brand" ? "blue" : "green"}
        >
          {text.toUpperCase()}
        </Tag>
      ),
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <StatusBadge status={text} />,
      sorter: true,
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      render: (text) => formatDate(text),
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleUserDetailsClick(record);
            }}
            size="small"
          >
            View Details
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="p-6">
      <Button onClick={() => navigate(-1)} className="mb-4">
        Back
      </Button>
      {/* Header with status indicator */}
      <Card className="mb-6">
        <Flex align="center" gap="large">
          <div className="w-32 h-24 flex items-center justify-center bg-gray-100 rounded">
            <IdcardOutlined style={{ fontSize: 40, color: "#1890ff" }} />
          </div>
          <div style={{ flexGrow: 1 }}>
            <Title level={2}>{supplierData.name}</Title>

            <div className="flex flex-wrap gap-4 mt-2">
              <Text>
                <GlobalOutlined className="mr-1" />{" "}
                {supplierData.country || "N/A"}
              </Text>

              {/* Status badge with improved visibility */}
              <div>
                <Text strong>Status: </Text>
                <StatusBadge
                  status={supplierData.status?.toUpperCase() || "N/A"}
                />
              </div>

              {/* Last connection with improved visibility */}
              <div>
                <Text strong>Last Connection: </Text>
                <Tag
                  color="blue"
                  icon={<ClockCircleOutlined />}
                  style={{ fontSize: "14px", padding: "2px 10px" }}
                >
                  {formatDate(supplierData.last_connection_date)}
                </Tag>
              </div>

              {/* Last email with improved visibility */}
              <div>
                <Text strong>Last Email: </Text>
                <Tag
                  color="purple"
                  icon={<MailOutlined />}
                  style={{ fontSize: "14px", padding: "2px 10px" }}
                >
                  {formatDate(supplierData.last_email_date)}
                </Tag>
              </div>
            </div>

            <div className="mt-2">
              <Text type="secondary">
                <CalendarOutlined className="mr-1" /> Created:{" "}
                {formatDate(supplierData.created_at)}
              </Text>
            </div>
          </div>

          <div>
            <Space>
              <Button
                type="primary"
                icon={<MailOutlined />}
                onClick={openEmailModal}
              >
                Send Email
              </Button>
            </Space>
          </div>
        </Flex>
      </Card>
      {/* Tabs for different sections */}
      <Card>
        <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
          <TabPane
            tab={
              <span>
                <IdcardOutlined className="mr-1" />
                Supplier Information
              </span>
            }
            key="info"
          >
            {/* Supplier Information Card */}
            <SupplierInfoTab supplierData={supplierData} />
          </TabPane>

          <TabPane
            tab={
              <span>
                <ShopOutlined className="mr-1" />
                Brands ({brands.length})
              </span>
            }
            key="brands"
          >
            {/* Brands Section */}
            <DataGrid
              columns={columnsBrand}
              data={brands}
              loading={loading.supplier}
              error={errors.supplier}
              onRetry={fetchSupplierData}
            />
          </TabPane>

          <TabPane
            tab={
              <span>
                <TeamOutlined className="mr-1" />
                Users ({loading.users ? "..." : users.length})
              </span>
            }
            key="users"
          >
            {/* Users Section */}
            <DataGrid
              columns={columnsUser}
              data={users}
              loading={loading.users}
              error={errors.users}
              onRetry={fetchSupplierUsers}
            />
          </TabPane>

          <TabPane
            tab={
              <span>
                <AppstoreOutlined className="mr-1" />
                Products ({loading.products ? "..." : productTotalCount})
              </span>
            }
            key="products"
          >
            {/* Products Section */}
            <DataGrid
              columns={columnsProduct}
              data={products}
              onRowClick={handleProductClick}
              serverSide={true}
              currentPage={productPage}
              pageSize={productPageSize}
              totalItems={productTotalCount}
              onChangePage={(newPage) => setProductPage(newPage)}
              onChangePageSize={(newPageSize) =>
                setProductPageSize(newPageSize)
              }
              searchValue={searchValue}
              onSearch={handleSearch}
              loading={loading.products}
              error={errors.products}
              onRetry={fetchSupplierProducts}
            />
          </TabPane>

          <TabPane
            tab={
              <span>
                <HistoryOutlined className="mr-1" />
                Email History (
                {loading.emailHistory ? "..." : emailHistory.length})
              </span>
            }
            key="emails"
          >
            {/* Email History Section */}
            <EmailHistoryTab
              loading={loading.emailHistory}
              error={errors.emailHistory}
              emailHistory={emailHistory}
              onRetry={fetchEmailHistory}
              onResendEmail={handleResendEmail}
            />
          </TabPane>
        </Tabs>
      </Card>
      {/* Email Modal */}
      <EmailModal
        visible={emailModalVisible}
        onCancel={closeEmailModal}
        onSend={handleSendEmail}
        users={users}
        loading={sendingEmail}
        emailHistory={emailHistory}
        emailTemplate={emailTemplate}
      />
    </div>
  );
};

export default SupplierDetail;
