// src/components/common/UserProfile.js
import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import {
  Card,
  Tabs,
  Button,
  Spin,
  message,
  Typography,
  Divider,
  Badge,
} from "antd";
import {
  ProfileOutlined,
  HistoryOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { getUser } from "../../services/userService";

// Import subcomponents
import UserProfileAvatar from "./profile/UserProfileAvatar";
import UserProfileInfo from "./profile/UserProfileInfo";
import OrganizationTab from "./profile/OrganizationTab";
import SecurityTab from "./profile/SecurityTab";
import ActivityTab from "./profile/ActivityTab";
import EmptyState from "./profile/EmptyState";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const UserProfile = ({ adminView = false }) => {
  const { user: currentUser } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  // Helper function to safely get userData properties
  const getUserProperty = (user, propName) => {
    if (!user) return null;
    if (user[propName] !== undefined) return user[propName];
    if (user.attributes && user.attributes[propName] !== undefined) {
      return user.attributes[propName];
    }
    return null;
  };

  // Use the ID from URL in admin view, otherwise use the current user's ID
  const userId = adminView ? id : getUserProperty(currentUser, "id");

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        if (adminView) {
          const response = await getUser(userId);
          setUserData(response.data.data);
        } else {
          setUserData(currentUser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        message.error("Failed to load user profile data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId, adminView, currentUser]);

  // Helper functions
  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    if (nameParts.length >= 2) {
      return `${nameParts[0][0]}${
        nameParts[nameParts.length - 1][0]
      }`.toUpperCase();
    }
    return name.substring(0, 2).toUpperCase();
  };

  const getUserAttributes = (user) => {
    if (!user) return {};
    if (user.attributes) return user.attributes;
    return user;
  };

  const getRoleColor = (role) => {
    switch (role) {
      case "admin":
        return "#f50";
      case "brand":
        return "#108ee9";
      case "supplier":
        return "#87d068";
      default:
        return "#ccc";
    }
  };

  const getInvitationStatusBadge = (status) => {
    switch (status) {
      case "accepted":
        return <Badge status="success" text="Accepted" />;
      case "pending":
        return <Badge status="warning" text="Pending" />;
      case "rejected":
        return <Badge status="error" text="Rejected" />;
      default:
        return <Badge status="default" text={status || "Unknown"} />;
    }
  };

  const goToSettings = () => {
    const role = getUserProperty(currentUser, "role");
    navigate(`/${role}/settings`);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Spin size="large" />
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="p-6">
        <Card>
          <div className="text-center py-8">
            <Title level={4}>User not found</Title>
            <Text type="secondary">
              The requested user profile could not be loaded.
            </Text>
            <div className="mt-4">
              <Button type="primary" onClick={() => window.location.reload()}>
                Try Again
              </Button>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  const attributes = getUserAttributes(userData);

  return (
    <div className="p-6">
      <Button onClick={() => navigate(-1)} className="mb-4">
        Back
      </Button>

      <Card bordered={false} className="mb-6">
        <div className="flex flex-col md:flex-row">
          {/* User Avatar Section */}
          <UserProfileAvatar
            user={attributes}
            adminView={adminView}
            getInitials={getInitials}
            getRoleColor={getRoleColor}
            getInvitationStatusBadge={getInvitationStatusBadge}
            goToSettings={goToSettings}
          />

          <Divider
            type="vertical"
            className="hidden md:block h-auto"
            style={{ height: "auto" }}
          />

          {/* User Information Section */}
          <div className="md:w-3/4 p-4">
            <UserProfileInfo
              attributes={attributes}
              adminView={adminView}
              currentUser={currentUser}
            />
          </div>
        </div>
      </Card>

      {/* Tabs Section */}
      <Tabs defaultActiveKey="organization" className="profile-tabs">
        <TabPane
          tab={
            <span>
              <ProfileOutlined /> Organization
            </span>
          }
          key="organization"
        >
          <OrganizationTab
            attributes={attributes}
            adminView={adminView}
            currentUser={currentUser}
          />
        </TabPane>

        <TabPane
          tab={
            <span>
              <HistoryOutlined /> Activity History
            </span>
          }
          key="activity"
        >
          <ActivityTab />
        </TabPane>

        <TabPane
          tab={
            <span>
              <LockOutlined /> Account Security
            </span>
          }
          key="security"
        >
          <SecurityTab
            attributes={attributes}
            adminView={adminView}
            getInvitationStatusBadge={getInvitationStatusBadge}
            goToSettings={goToSettings}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default UserProfile;
