
// src/components/common/profile/EmptyState.js
import React from "react";
import { Typography } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const EmptyState = ({ title, description }) => (
  <div className="text-center py-8">
    <InboxOutlined style={{ fontSize: 48 }} className="text-gray-300 mb-4" />
    <Title level={4}>{title}</Title>
    <Text type="secondary">{description}</Text>
  </div>
);

export default EmptyState;