// src/components/common/profile/OrganizationTab.js
import React from "react";
import { Link } from "react-router-dom";
import { Card, Descriptions, Tag, Typography } from "antd";
import {
  ShopOutlined,
  IdcardOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import EmptyState from './EmptyState';

const { Title, Text } = Typography;

const OrganizationTab = ({ attributes, adminView, currentUser }) => {
  if (attributes.role === "brand" && attributes.brand) {
    return (
      <Card>
        <div>
          <Title level={4}>Brand Information</Title>
          <Descriptions bordered column={{ xs: 1, sm: 2 }}>
            <Descriptions.Item label="Brand Name" span={2}>
              <ShopOutlined className="mr-2" />
              {adminView && attributes.brand_id ? (
                <Link to={`/${currentUser.role}/brands/${attributes.brand_id}`}>
                  {attributes.brand.name}
                </Link>
              ) : (
                attributes.brand.name
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Country">
              <GlobalOutlined className="mr-2" />
              {attributes.brand.country}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {attributes.brand.address || "N/A"}
            </Descriptions.Item>

            {attributes.brand.license_types && attributes.brand.license_types.length > 0 && (
              <Descriptions.Item label="License Types" span={2}>
                {attributes.brand.license_types.map((license) => (
                  <Tag key={license} className="mb-1 mr-1">
                    {license}
                  </Tag>
                ))}
              </Descriptions.Item>
            )}

            {attributes.brand.active_license_types && attributes.brand.active_license_types.length > 0 && (
              <Descriptions.Item label="Active Licenses" span={2}>
                {attributes.brand.active_license_types.map((license) => (
                  <Tag color="green" key={license} className="mb-1 mr-1">
                    {license}
                  </Tag>
                ))}
              </Descriptions.Item>
            )}
          </Descriptions>

          {attributes.brand.available_form_templates && attributes.brand.available_form_templates.length > 0 && (
            <div className="mt-4">
              <Title level={5}>Available Form Templates</Title>
              <div className="mt-2">
                {attributes.brand.available_form_templates.map((template) => (
                  <Card key={template.id} size="small" className="mb-2">
                    <div className="flex justify-between items-center">
                      <div>
                        <Text strong>{template.name}</Text>
                        <div>
                          <Text type="secondary">Code: {template.code}</Text>
                        </div>
                      </div>
                      <div>
                        <Tag color="blue">v{template.version}</Tag>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          )}
        </div>
      </Card>
    );
  } 
  
  if (attributes.role === "supplier" && attributes.supplier) {
    return (
      <Card>
        <div>
          <Title level={4}>Supplier Information</Title>
          <Descriptions bordered column={{ xs: 1, sm: 2 }}>
            <Descriptions.Item label="Supplier Name" span={2}>
              <IdcardOutlined className="mr-2" />
              {adminView && attributes.supplier_id ? (
                <Link to={`/${currentUser.role}/suppliers/${attributes.supplier_id}`}>
                  {attributes.supplier.name}
                </Link>
              ) : (
                attributes.supplier.name
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Country">
              <GlobalOutlined className="mr-2" />
              {attributes.supplier.country}
            </Descriptions.Item>
            {adminView && (
              <Descriptions.Item label="Status">
                <Tag color={attributes.supplier.status === "active" ? "green" : "orange"}>
                  {attributes.supplier.status?.toUpperCase() || "N/A"}
                </Tag>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Address" span={adminView ? 2 : 1}>
              {attributes.supplier.address || "N/A"}
            </Descriptions.Item>
            {adminView && (
              <>
                <Descriptions.Item label="TVA Number">
                  {attributes.supplier.tva_number || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Supplier Code">
                  {attributes.supplier.code_supplier || "N/A"}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>

          {attributes.supplier.brands && attributes.supplier.brands.length > 0 && (
            <div className="mt-4">
              <Title level={5}>Associated Brands</Title>
              <div className="mt-2">
                {attributes.supplier.brands.map((brand) => (
                  <Card key={brand.id} size="small" className="mb-2">
                    <div className="flex justify-between items-center">
                      <div>
                        {adminView ? (
                          <Link to={`/${currentUser.role}/brands/${brand.id}`}>
                            <Text strong>{brand.name}</Text>
                          </Link>
                        ) : (
                          <Text strong>{brand.name}</Text>
                        )}
                        <div>
                          <Text type="secondary">
                            <GlobalOutlined className="mr-1" />
                            {brand.country}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          )}

          {attributes.supplier.available_form_templates && attributes.supplier.available_form_templates.length > 0 && (
            <div className="mt-4">
              <Title level={5}>Available Form Templates</Title>
              <div className="mt-2">
                {attributes.supplier.available_form_templates.map((template) => (
                  <Card key={template.id} size="small" className="mb-2">
                    <div className="flex justify-between items-center">
                      <div>
                        <Text strong>{template.name}</Text>
                        <div>
                          <Text type="secondary">Code: {template.code}</Text>
                        </div>
                      </div>
                      <div>
                        <Tag color="blue">v{template.version}</Tag>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          )}
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <EmptyState
        title="No Organization Information"
        description="This user is not associated with any organization"
      />
    </Card>
  );
};

export default OrganizationTab;